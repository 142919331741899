<template>
  <span>

  <v-btn title="Click to copy" @click="copy" rounded depressed x-small color="green lighten-5 black--text">{{label}}<v-icon x-small>mdi-content-copy</v-icon></v-btn>
  <textarea style="display: none" :id="'copyInput-'+uuid">{{content}}</textarea>
 <v-snackbar v-model="success">{{successMessage}}</v-snackbar>
  </span>

</template>

<script>
export default {
  props:["content","label","uuid"],
  name: "copyBottun",
  data(){
    return{
      success:false,
      successMessage:"",
    }
  },
  methods:{
    copy(){

      const input = document.getElementById("copyInput-"+this.uuid);
      navigator.clipboard.writeText(input.textContent).then(() => {
       this.successMessage= "Copied successfully, you can now paste anywhere";
       this.success=true;

      });


    }
  }
}
</script>

<style scoped>

</style>