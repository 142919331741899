var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"4"}},[_c('v-stepper',{staticClass:"pa-0",staticStyle:{"box-shadow":"none !important"},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',{staticClass:"pa-0",staticStyle:{"box-shadow":"none !important"}},[_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"1"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',[_c('h1',{staticClass:"font-weight-light red--text"},[_vm._v(" Subscription Ended ")]),_c('h3',{staticClass:"text-h6 font-weight-light"},[_vm._v(" Your subscription for "),_c('strong',{staticClass:"fw-bold"},[_vm._v(_vm._s(_vm.$store.state.user.user_infor.current_business.name))]),_vm._v(" has ended. ")])]),(
                  _vm.$store.state.user.user_infor.current_business.subscription
                    .name !== 'none'
                )?_c('v-alert',{attrs:{"color":"warning lighten-5 warning-text"}},[_c('div',[_c('h3',[_vm._v(" Only businesses active on "),_c('strong',[_vm._v("Starter")]),_vm._v(", "),_c('strong',[_vm._v("Enterprise")]),_vm._v(", "),_c('strong',[_vm._v("Venture")]),_vm._v(", "),_c('strong',[_vm._v("Pro")]),_vm._v(" and "),_c('strong',[_vm._v("Plus")]),_vm._v(" are allowed to have multiple user access. ")]),_c('strong',[_vm._v("Contact the owner of this business to upgrade their plan")])])]):_vm._e(),_c('v-btn',{attrs:{"color":"green","dark":"","block":"","x-large":"","rounded":"","depressed":""},on:{"click":function($event){_vm.showPlans = true}}},[_vm._v("Subscribe to a plan to continue ")]),(
                  _vm.mybusinesses ||
                  _vm.$store.state.user.user_infor.invited_businesses
                )?_c('v-btn',{staticClass:"font-weight-light my-4 mx-auto text-center white--text",attrs:{"color":"blue","block":"","rounded":"","x-large":""},on:{"click":function($event){_vm.showBusinesses = true}}},[_vm._v("Switch to a different business ")]):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500"},model:{value:(_vm.showBusinesses),callback:function ($$v) {_vm.showBusinesses=$$v},expression:"showBusinesses"}},[_c('v-card',{staticClass:"pa-5 mx-auto",staticStyle:{"overflow":"auto"},attrs:{"elevation":"1","rounded":"lg"}},[(_vm.invitedBusinesses && _vm.invitedBusinesses.length)?_c('v-card-title',{staticClass:"fw-bold"},[_vm._v("Businesses You were invited to ")]):_vm._e(),_vm._l((_vm.invitedBusinesses),function(business){return (
                      business.id !==
                      _vm.$store.state.user.user_infor.current_business.id
                    )?_c('business-item',{key:business.id,attrs:{"business":business}}):_vm._e()}),(_vm.progress)?_c('v-skeleton-loader',{attrs:{"type":"list-item@10"}}):_vm._e(),(_vm.mybusinesses)?_c('div',[_c('v-card-title',{staticClass:"fw-bold mt-3 mb-1"},[_vm._v("Your Businesses ")]),_vm._l((_vm.mybusinesses),function(business){return _c('business-item',{key:business.id,attrs:{"business":business}})})],2):_vm._e()],2)],1),(
                  _vm.$store.state.user.user_infor.current_business.subscription
                    .name !== 'none'
                )?_c('v-btn',{attrs:{"large":"","block":"","rounded":"","depressed":"","color":"blue lighten-5 blue--text"},on:{"click":function($event){_vm.step = 2}}},[_vm._v("Create a new business ")]):_vm._e(),_c('v-btn',{attrs:{"large":"","block":"","rounded":"","depressed":"","color":"red lighten-5 red--text mt-2"},on:{"click":function($event){_vm.logoutDialog = true}}},[_vm._v(" Logout ")])],1)],1)],1),_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"2"}},[_c('new-business-component',{on:{"back":function($event){_vm.step = 1}}})],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[(_vm.showPlans)?_c('plans-component'):_vm._e()],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.logoutDialog),callback:function ($$v) {_vm.logoutDialog=$$v},expression:"logoutDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$t("main.confirm"))+" ")]),_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.$t("main.logout_message")))])]),_c('v-card-actions',[_c('v-btn',{attrs:{"loading":_vm.logoutLoading,"block":"","large":"","color":"red","dark":"","rounded":"","depressed":""},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t("main.logout"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }