<template>
  <v-card flat>
    <v-tabs
        v-model="tab"

    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab
          v-for="(result,index) in results"
          :href="'#'+result.name+'-tab'"
          :key="index"
      >
        <v-badge
            color="red"
            :content="result.items.length"
        >
          {{result.name}}
        </v-badge>
      </v-tab>


    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
          v-for="(result,index) in results"
          :key="index"
          :value="result.name+'-tab'"
      >
        <v-card flat>

            <SearchInvoices @close="$emit('closeDialog')" v-if="result.name==='Invoices'" :items="result.items"></SearchInvoices>
            <SearchCustomers @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Customers'"></SearchCustomers>
            <SearchSuppliers @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Suppliers'" ></SearchSuppliers>
            <SearchQuotes @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Quotes'"></SearchQuotes>
            <search-inventory @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Products & Services'" ></search-inventory>
            <search-employees @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Employees'" ></search-employees>
            <payrun-search @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Payruns'"></payrun-search>
            <account-search  @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Accounts'"></account-search>
            <search-expenses @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Expenses'"></search-expenses>
            <search-tags @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Tags'"></search-tags>
            <search-loans  @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Loans'"></search-loans>
            <search-bills @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Bills'"></search-bills>
            <search-sales @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Quick Sales'"></search-sales>
            <search-transfers @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Bank Transfers'"></search-transfers>
            <search-withholding-payment @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Withholding Tax Payments'"></search-withholding-payment>
            <search-income-tax-payment @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Income Tax Payments'"></search-income-tax-payment>
          <search-sync-income @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Sync Incomes'"></search-sync-income>
          <search-sync-expense @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Sync Expenses'"></search-sync-expense>
          <search-journals @close="$emit('closeDialog')" :items="result.items" v-if="result.name==='Journals'"></search-journals>

        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-card-text v-if="results && results.length<1">
      <center>

      <v-img
          src="/img/icons/noresults.svg"
          width="150px"
          class="m-3"

      ></v-img>
      <h1 class="font-weight-light">We could not find anything related to the keyword provided</h1>
        <small>Please modify your search and try again </small>
      </center>

    </v-card-text>
  </v-card>

</template>

<script>
import SearchInvoices from "./SearchInvoices";
import SearchCustomers from "./SearchCustomers";
import SearchSuppliers from "./SearchSuppliers";
import SearchQuotes from "./SearchQuotes";
import SearchInventory from "./SearchInventory";
import SearchEmployees from "./SearchEmployees";
import PayrunSearch from "./PayrunSearch";
import AccountSearch from "./AccountSearch";
import SearchExpenses from "./SearchExpenses";
import SearchTags from "./SearchTags";
import SearchLoans from "./SearchLoans";
import SearchBills from "./SearchBills";
import SearchSales from "./SearchSales";
import SearchTransfers from "./SearchTransfers";
import SearchWithholdingPayment from "./SearchWithholdingPayment";
import SearchIncomeTaxPayment from "./SearchIncomeTaxPayment";
import SearchJournals from "./SearchJournals";
import SearchSyncIncome from "./SearchSyncIncome";
import SearchSyncExpense from "./SearchSyncExpense";
export default {
  components: {
    SearchSyncExpense,
    SearchSyncIncome,
    SearchJournals,
    SearchIncomeTaxPayment,
    SearchWithholdingPayment,
    SearchTransfers,
    SearchSales,
    SearchBills,
    SearchLoans,
    SearchTags,
    SearchExpenses,
    AccountSearch,
    PayrunSearch,
    SearchEmployees, SearchInventory, SearchQuotes, SearchSuppliers, SearchCustomers, SearchInvoices},
  props:['results'],
  name: "SearchResults",
  data(){
    return{
      tab:""
    }
  },
  watch:{
    results(){
      if (this.results){
        this.tab = this.results[0].name+'-tab';
      }
    }
  }
}
</script>

<style scoped>

</style>