<template>
	<v-menu
		:close-on-content-click="true"
		offset-x
		transition="slide-x-transition"
		hide-overlay="false"
		absolute
		style="max-height: 100% !important"
	>
		<template v-slot:activator="{ on }">
			<v-btn
				color="warning"
				dark
				light
				v-on="on"
				fab
				x-small
				depressed
				id="addShortcustBtn"
			>
				<v-icon>mdi-plus</v-icon>
				<!--{{$t('main.new')}}-->
			</v-btn>
		</template>

		<v-card style="max-height: 100% !important; overflow-y: auto">
			<v-card-text>
				<v-row>
					<v-col cols="12" md="3" class="pr-1">
						<h3 class="text-dark ml-5 mb-0 pb-0">{{ $t("main.customer") }}</h3>
						<router-link to="/invoices/new" class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.invoice") }}</router-link
						><br />
						<router-link to="/quotes/new" class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.quote") }}</router-link
						><br />
						<router-link to="/sales/new" class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.quick_sale") }}</router-link
						><br />
						<a
							:href="'https://pos.built.africa/?s=' + token"
							target="_blank"
							class="btn btn-link text-muted"
							>{{ $t("quick_sale.launch_pos")
							}}<v-icon x-small style="text-decoration: none"
								>mdi-arrow-top-right</v-icon
							></a
						><br />
						<router-link
							to="/customers"
							@click.native="g_new_customer"
							class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.customer") }}</router-link
						>
					</v-col>

					<v-col cols="12" md="3" class="pr-1 pl-1">
						<h3 class="text-dark ml-5 mb-0 pb-0">{{ $t("main.supplier") }}</h3>
						<router-link class="btn btn-link text-muted" to="/expenses/newbill"
							>{{ new_text }} {{ $t("main.bill") }}</router-link
						><br />
						<router-link
							class="btn btn-link text-muted"
							@click.native="$store.state.newcashexpense = true"
							to="/paidexpenses"
							>{{ new_text }} {{ $t("main.paid_expense") }}</router-link
						><br />
						<router-link
							@click.native="g_new_supplier"
							to="/suppliers"
							class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.supplier") }}</router-link
						>
					</v-col>

					<v-col cols="12" md="3" class="pr-1 pl-1">
						<h3 class="text-dark ml-5 mb-0 pb-0">{{ $t("main.payroll") }}</h3>
						<router-link
							@click.native="$store.state.new_employee = true"
							to="/payroll/employees"
							class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.employee") }}</router-link
						><br />
						<router-link
							to="/payroll/payruns"
							@click.native="$store.state.new_payrun = true"
							class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.payrun") }}</router-link
						>
					</v-col>

					<v-col cols="12" md="5">
						<h3 class="text-dark ml-5 mb-0 pb-0">{{ $t("main.others") }}</h3>
						<router-link
							@click.native="g_new_item"
							to="/inventory"
							class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.product_service") }}</router-link
						><br />
						<router-link
							to="/inventory/newadjustment"
							class="btn btn-link text-muted"
							>{{ new_text }} {{ $t("main.inventory_adjustment") }}</router-link
						><br />
						<router-link
							to="/incomes"
							@click.native="g_new_income"
							class="btn btn-link text-muted"
							>{{ $t("main.other_incomes") }}</router-link
						><br />
						<router-link class="btn btn-link text-muted" to="/taxpayment"
							>{{ new_text }} {{ $t("main.sales_tax_payment") }}</router-link
						><br />
						<router-link
							@click.native="$store.state.new_transfer = true"
							class="btn btn-link text-muted"
							to="/banktransfer"
							>{{ $t("main.record_bank_records") }}</router-link
						><br />
						<router-link class="btn btn-link text-muted" to="/loans">{{
							$t("main.record_loan")
						}}</router-link>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-menu>
</template>

<script>
import { getAccessToken } from "../utils";
export default {
	name: "addShortcut",
	computed: {
		new_text() {
			return this.$t("main.new");
		},
		token() {
			return getAccessToken();
		},
	},
	methods: {
		g_new_income() {
			this.$store.state.new_income = true;
		},
		g_new_item() {
			this.$store.state.new_item = true;
		},
		g_new_expense() {
			this.$store.state.new_expense = true;
		},
		g_new_customer() {
			this.$store.state.new_customer = true;
		},
		g_new_supplier() {
			this.$store.state.new_supplier = true;
		},
	},
};
</script>

<style scoped></style>
