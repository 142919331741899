<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          class="border"
          style="text-decoration: none;"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-black"><account-button :account="item"></account-button></v-list-item-title>

          <v-list-item-subtitle>
            Balance:{{item.currecny}}{{item.fx_balance | absolute | currency_symbol}}
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="blue"
              to="/accounts"
              block
              @click="$emit('close'); $router.push('/accounts')"
          >Go to Accounts <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>

</template>

<script>
import AccountButton from "../patials/accountButton";
export default {
  components: {AccountButton},
  props:['items'],
  name: "AccountSearch"
}
</script>

<style scoped>

</style>