<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          :to="'/suppliers/'+item.id"
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/suppliers/'+item.id)"
      >
        <v-list-item-avatar><v-img src="/img/photo.png"></v-img></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{item.supplier_name}}</v-list-item-title>
          <v-list-item-subtitle v-if="item.supplier_phone">Phone Number:{{item.supplier_phone}}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.supplier_address">Address:{{item.supplier_address}}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.balance" class="red--text">You Owe:{{item.balance  | currency_symbol}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-col>
  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchSuppliers"
}
</script>

<style scoped>

</style>