<template>
    <div>

        <v-banner style="width: 100%" class="p-0 text-center bg-success" dark>
            Please confirm your account to continue to use Built. Didn’t get email? <v-btn @click="resend_verirfication()" :loading="progress" x-small dark color="blue">Resend link</v-btn>
        </v-banner>

        <v-snackbar
                v-model="error_message"
                color="error"
        >
            {{error_msg}}


        </v-snackbar>
        <v-snackbar
                v-model="success_message"
        >
            {{toast_msg}}

        </v-snackbar>
    </div>

</template>

<script>
    export default {
        name: "confirmWarning",
        data(){
            return{
                progress:false,
                toast_msg:'',
                success_message:false,
                error_msg:'',
                error_message:false
            }
        },
        methods:{
            resend_verirfication(){

                this.progress = true;
                axios.get('/api/sendverify')
                    .then(res=>{
                        this.progress = false;
                        this.toast_msg = "Email verification sent, please check your inbox to verify your account";
                        this.success_message = true;
                    })
                    .catch(()=>{
                        this.progress = false;

                        this.error_msg = "Something went wrong, please try again";
                        this.error_message = true;
                    });


            },

        }
    }
</script>

<style scoped>

</style>