<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-btn
          to="/"
          text
          :color="$vuetify.theme.dark ? 'blue' : 'blue darken-4'"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("main.back") }}
        </v-btn>
        <span class="display-1 font-weight-light">Income</span>

<!--        <span-->
<!--          class="font-weight-bold"-->
<!--          :class="$vuetify.theme.dark ? '' : 'text&#45;&#45;darken-4'"-->
<!--          style="font-size: 2rem"-->
<!--          >Income</span-->
<!--        >-->
      </v-col>

      <v-col cols="12" sm="3" v-for="(menu, index) in menus" :key="index">
        <menue-card
          :title="menu.title"
          :desription="menu.description"
          :icon="menu.icon"
          :route="menu.route"
          :blank="menu.blank"
        ></menue-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MenueCard from "./MenueCard.vue";
import {getAccessToken} from "../../utils";

export default {
  components: { MenueCard },
  name: "IncomeMenu",
  data() {
    return {
      menus: [
        {
          title: "Invoices",
          description: "Create and share invoices, get paid globally.",
          route: "/invoices",
          icon: "mdi-receipt-outline",
          blank: false,
        },
        {
          title: "Quick Sales",
          description:
            "Manage rapid transactions without formal invoices hassle.",
          route: "/sales",
          icon: "mdi-cart-outline",
          blank: false,
        },

        {
          title: "POS",
          description: "Instant in-store sales, seamless Built POS payments.",
          route:
            "https://pos.built.africa/?s=" + getAccessToken(),
          icon: "mdi-printer-pos",
          blank: true,
        },
        {
          title: "Deposits",
          description: "Receive and return deposits or deferred payments.",
          icon: "mdi-cash-fast",
          route: "/deposits",
        },

        {
          title: "Quotes",
          description: "Estimate sharing, tracking customer quotes made easy.",
          route: "/quotes",
          icon: "mdi-file-delimited-outline",
          blank: false,
        },
        {
          title: "Other Incomes",
          description: "Log diverse non-operational income sources securely.",
          route: "/incomes",
          icon: "mdi-cash-plus",
          blank: false,
        },
        //  {
        //     title:"Loans",
        //     description:"Record loans that you collected for your business and loan payments.",
        //     route:"/loans",
        //     icon:"mdi-account-cash"
        // },
        {
          title: "Customers",
          description: "Create, view and manage your customers.",
          route: "/customers",
          icon: "mdi-account-multiple-outline",
          blank: false,
        },
      ],
    };
  },
};
</script>