<template>
  <v-card
    id="selectedBusiness"
    :class="$vuetify.theme.isDark ? 'mx-auto black' : 'mx-auto border-bottom'"
    class="ma-2 pl-2 mr-4"
    flat
    style="
      top: 0 !important;
      position: absolute !important;
      width: 95%;
      z-index: 99;
      height: 64px;
    "
  >
    <v-card-text class="pa-0">
      <!--DISPLAY CURRENT BUSINESS WITH CHEVRON  -->
      <div v-if="$store.state.user.user_infor.has_business" class="pt-2">
        <v-row
          id="manageBusinessesButton"
          dense
          style="cursor: pointer"
          @click="showMenu = true"
        >
          <v-col cols="3">
            <v-avatar>
              <v-img
                :src="`${$store.state.user.user_infor.current_business.logo}`"
                alt="logo"
                contain
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="6">
            <div
              :title="$store.state.user.user_infor.current_business.name"
              class="trim-text"
            >
              {{ $store.state.user.user_infor.current_business.name }}
              <small class="d-block text--disabled">
                ID:{{
                  currentBusiness.owner_id +
                  "-" +
                  currentBusiness.country_id +
                  "-" +
                  currentBusiness.id
                }}</small
              >
            </div>
          </v-col>
          <v-col cols="2">
            <v-icon v-if="!showMenu" class="mt-3">mdi-chevron-down</v-icon>
            <v-icon v-else class="mt-3">mdi-chevron-up</v-icon>
          </v-col>
        </v-row>
      </div>
      <span v-else>
        <em>{{ $t("main.no_business") }}</em>
      </span>
    </v-card-text>

    <!-- SHOW ALL BUSINESSES DROPDOWN -->
    <v-menu
      v-model="showMenu"
      :absolute="false"
      :allow-overflow="true"
      :close-on-content-click="false"
      :position-x="0"
      :position-y="77"
      attach="#sidenav"
      class="menuClass"
      fixed
      min-width="210"
      transition="slide-y-transition"
    >
      <v-sheet style="max-height: 50vh; overflow: auto">
        <v-list>
          <v-list-item v-if="!hasMoreBusinesses">
            <v-list-item-content>
              <v-list-item-title class="fw-bold" style="font-size: 0.6rem"
                >You have no other businesses</v-list-item-title
              >
              <v-list-item-subtitle
                class="fw-light text-capitalize"
                style="font-size: 0.6rem"
                >You create other businesses by <br />
                clicking on the + Add a new Business
                button</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-btn
              block
              color="blue darken-4"
              dark
              depressed
              rounded
              text
              @click="addDialog = true"
              style="font-size: 0.8rem"
              class="fw-bold mx-auto"
            >
              <v-icon>mdi-plus</v-icon>
              Add A New Business
            </v-btn>
          </v-list-item>
          <v-list-item v-if="hasMoreBusinesses">
            <v-text-field
              v-model="search"
              :placeholder="$t('main.search')"
              class="mx-auto"
              dense
              filled
              flat
              full-width
              rounded
            ></v-text-field>
          </v-list-item>
          <v-subheader
            v-if="filteredBusinesses.businesses.length > 0"
            v-show="hasMoreBusinesses"
            class="mt-0"
            >Your Businesses
          </v-subheader>
          <v-list-item
            v-for="business in filteredBusinesses.businesses"
            v-if="
              $store.state.user.user_infor.current_business.id !== business.id
            "
            v-show="hasMoreBusinesses"
            :key="business.id"
            :title="business.name"
            class="border-bottom"
            @click="selectBusiness(business)"
          >
            <v-list-item-avatar>
              <v-img :src="`${business.logo}`" alt="logo" contain></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="trim-text"
                >{{ business.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ business.address }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                >ID:{{
                  business.owner_id +
                  "-" +
                  business.country_id +
                  "-" +
                  business.id
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-subheader
            v-show="hasMoreBusinesses"
            v-if="filteredBusinesses.invitedBusinesses.length > 0"
            >Invited Businesses
          </v-subheader>
          <v-list-item
            v-show="hasMoreBusinesses"
            v-for="business in filteredBusinesses.invitedBusinesses"
            v-if="
              $store.state.user.user_infor.current_business.id !== business.id
            "
            :key="business.id"
            :title="business.name"
            class="border-bottom mb-4"
            @click="selectBusiness(business)"
          >
            <v-list-item-avatar>
              <v-img :src="`${business.logo}`" alt="logo" contain></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="trim-text"
                >{{ business.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{ business.address }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                >ID:{{
                  business.owner_id +
                  "-" +
                  business.country_id +
                  "-" +
                  business.id
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-menu>
    <!-- CREATE BUSINESS MODAL -->
    <v-dialog
      v-model="addDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
      scrollable
      width="500"
    >
      <new-business-component
        :businessSwitch="true"
        @closeDialog="addDialog = false"
      ></new-business-component>

    </v-dialog>
    <!-- BUSINESS SWITCHER -->
    <v-dialog v-model="activateDialog" width="450px">
      <v-card>
        <v-card-title>Confirm Switch</v-card-title>
        <v-card-text class="p-3 text-dark"
          >This will switch your business to the one you have just selected.
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="isActivateLoading"
            block
            color="blue darken-4"
            dark
            depressed
            large
            rounded
            @click="activateBusiness"
            >Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    <tour-component-->
    <!--      v-if="!$vuetify.breakpoint.mobile"-->
    <!--      description="Manage your businesses here"-->
    <!--      index="manageBusinessesButton_index"-->
    <!--      target="#manageBusinessesButton"-->
    <!--    ></tour-component>-->
  </v-card>
</template>

<script>
import axios from "axios";
import { PREVIEW_URL } from "../../data";
import TourComponent from "../tour/TourComponent";
import NewBusinessComponent from "../../components/patials/NewBusinessComponent.vue";

export default {
  name: "BusinessesSelector",
  components: { TourComponent, NewBusinessComponent },
  data() {
    return {
      business_email: "",
      business_phone: "",
      business_address: "",
      business_bank_name: "",
      business_account_name: "",
      business_account_number: "",
      business_name: "",
      invoice_prefix: "INV",
      imgsrc: "",
      logo: [],
      imageLoading: false,
      imgSizeError: false,
      country_id: null,
      currency_id: null,
      industry_id: null,
      bank_name: "",
      account_name: "",
      account_number: "",
      logo_error:
        "Logo size too big, please select a smaller image. Image size should not be more than 500kb ",
      image_error: false,
      search: "",
      showMenu: false,
      selectedBusiness: null,
      businesses: [],
      invitedBusinesses: [],
      add_dialog: false,
      progress: false,
      step: 1,
      businessModel: {
        name: "",
        email: "",
        phone_number: "",
        invoice_id: "",
        account_name: "",
        account_number: "",
        bank_name: "",
        country_id: "",
        currency_id: "",
        industry_id: "",
        logo: null,
      },
      rules: [(value) => !!value || "Field is required"],
      previewURL: PREVIEW_URL,
      tab: null,
      addDialog: false,
      activateDialog: false,
      isSelecting: false,
      isSaving: false,
      isActivateLoading: false,
      hasErrorMsg: false,
      hasSuccessMsg: false,
      hasImageError: false,
      successMsg: "",
      errorMsg: "",
      industries: [],
      countries: [],
      currencies: [],
      getting_industries: false,
      getting_currencies: false,
      getting_countries: false,
      saving_progress: false,
      business_name_rules: [(value) => !!value || "Business Name is Required."],
      industry_rules: [(value) => !!value || "PLease select your industry"],

      country_rules: [(value) => !!value || "PLease select your country"],
      currency_rules: [(value) => !!value || "PLease select your currency"],
    };
  },
  computed: {
    currentBusiness() {
      return this.$store.state.user.user_infor.current_business;
    },
    baseUrl() {
      return this.$store.state.baseURL;
    },
    hasMoreBusinesses() {
      const list = {
        businesses: this.businesses.filter(
          (b) => b.id !== this.currentBusiness.id
        ),
        invitedBusinesses: this.invitedBusinesses.filter(
          (b) => b.id !== this.currentBusiness.id
        ),
      };

      return list.businesses.length + list.invitedBusinesses.length > 0;
    },
    filteredBusinesses() {
      if (!this.search) {
        return {
          businesses: this.businesses,
          invitedBusinesses: this.invitedBusinesses,
        };
      }

      return {
        businesses: this.businesses.filter((b) =>
          b.name.toLowerCase().includes(this.search)
        ),
        invitedBusinesses: this.invitedBusinesses.filter((b) =>
          b.name.toLowerCase().includes(this.search)
        ),
      };
    },
  },
  methods: {
    selectPass() {
      let pass = document.getElementById("logopass");
      pass.click();
    },

    logoChange(e) {
      this.image_error = false;
      this.logo = e.target.files[0];
      if (this.logo.size < 532598 && this.logo) {
        this.imageLoading = true;
        const fr = new FileReader();
        let photo = fr.readAsDataURL(this.logo);
        fr.onload = () => {
          this.previewURL = fr.result;
          this.imageLoading = false;
        };
      } else {
        this.image_error = true;
        this.imageLoading = false;
      }
    },
    async activateBusiness() {
      this.isActivateLoading = true;

      axios
        .get(`/api/activatebusiness/${this.selectedBusiness.id}`)
        .then((res) => {
          this.getBusinesses();
          this.hasSuccessMsg = true;
          this.successMsg = `${this.selectedBusiness.name} was activated successfully`;
          this.$router.push("/");
          location.reload();
          this.isActivateLoading = false;
        })
        .catch((error) => {
          this.hasErrorMessage = true;
          this.activateDialog = false;
          this.isActivateLoading = false;
          this.errorMsg = "Something went wrong, could not activate business";
        });
    },
    selectBusiness(business) {
      this.selectedBusiness = business;
      this.activateDialog = true;
      this.showMenu = false;
    },
    async getBusinesses() {
      const response = await axios.get("/api/getbusinesses");
      this.businesses = response.data;
    },
    get_currencies() {
      this.getting_currencies = true;
      axios.get("/api/getcurrencies").then((res) => {
        this.currencies = res.data;
        this.getting_currencies = false;
      });
    },
    get_countries() {
      this.getting_countries = true;
      axios.get("/api/getcountries").then((res) => {
        this.countries = res.data;
        this.getting_countries = false;
      });
    },
    get_industries() {
      this.getting_industries = true;
      axios.get("/api/getindustries").then((res) => {
        this.industries = res.data;
        this.getting_industries = false;
      });
    },
    getInvitedBusinesses() {
      axios.get("/api/business/invited").then((res) => {
        this.invitedBusinesses = res.data;
      });
    },
    create_business() {
      if (this.$refs.business_form.validate()) {
        this.saving_progress = true;

        let business_data = new FormData();
        business_data.append("name", this.business_name);
        business_data.append("invoice_id", this.invoice_prefix);
        business_data.append("address", this.business_address);
        business_data.append("email", this.business_email);
        business_data.append("country_id", this.country_id);
        business_data.append("currency_id", this.currency_id);
        business_data.append("industry_id", this.industry_id);
        business_data.append("location", this.business_location);
        business_data.append("phone_number", this.business_phone);
        business_data.append("bank_name", this.business_bank_name);
        business_data.append("account_number", this.business_account_number);
        business_data.append("account_name", this.business_account_name);
        business_data.append("active", 1);
        business_data.append("logo", this.logo);
        business_data.append("is_retail", 0);
        business_data.append("is_service", 1);

        axios
          .post("/api/createbusiness", business_data)
          .then((res) => {
            window.location = "/";

            this.add_dialog = false;
          })
          .catch(() => {
            this.error_message = true;
            this.error_msg = "Something went wrong, please check and try again";
            this.progress = false;
          });
      }
    },
  },
  mounted() {
    this.getBusinesses();
    this.getInvitedBusinesses();
    this.get_industries();
    this.get_countries();
    this.get_currencies();
  },
};
</script>

<style scoped>
.trim-text {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
  font-size: small;
}

.menuClass {
  padding-bottom: 50px;
}
</style>
