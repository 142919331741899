<template>
    <div>
        <v-list three-line v-if="debtors.length >0">
            <template v-for="(debtor, index) in debtors">


                <v-list-item
                    :key="debtor.id"
                    link
                    v-if="index <=7"
                >
                    <v-list-item-avatar>
                        <v-img src="/img/photo.png"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn text color="blue" :to="'/customers/'+debtor.id" target="_blank" >{{debtor.business_name}}<v-icon>mdi-arrow-top-right</v-icon></v-btn>

                        </v-list-item-title>
                    </v-list-item-content>
                    <v-chip color="green lighten-5" class="font-weight-bold text-success">{{debtor.balance | currency_symbol}}</v-chip>
                </v-list-item>
            </template>

            <template>
                <v-list-item>
                    <v-btn rounded block text color="blue" to="/customers"> View All</v-btn>
                </v-list-item>
            </template>
        </v-list>
        <center class="mt-5" v-else>
            <v-icon x-large class="text-muted mt-5">mdi-account-off-outline</v-icon>
            <h4>You have no customer at the moment</h4>
            <v-btn small rounded text color="blue" to="/customers" @click="$store.state.new_customer=true" >Create Customer</v-btn>
        </center>
    </div>

</template>

<script>
    export default {
        name: "debtorsComponent",
        props:['debtors'],



    }
</script>

<style scoped>

</style>
