<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/paidexpenses/'+item.id+'?itemid='+item.id)"
          :to="'/paidexpenses/'+item.id+'?itemid='+item.id"
      >
        <v-list-item-content>
          <v-list-item-title class="font-weight-black warning--text">{{item.fx_currency}}{{item.amount | toMoney}}</v-list-item-title>

          <v-list-item-subtitle>
            Payment Account:{{item.payment_account.account_name}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Expense Account:{{item.expense_account.account_name}}
          </v-list-item-subtitle>

          <v-list-item-subtitle>
            Description:{{item.description}}
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="warning"
              to="/paidexpenses"
              block
              @click="$emit('close'); $router.push('/paidexpenses')"
          >Go to Accounts <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchExpenses"
}
</script>

<style scoped>

</style>