<template>
          <v-card>
            <v-card-title class="font-weight-light">
                Add Reminders to this invoice
                <v-spacer></v-spacer>
                <v-btn @click="$emit('close_dialog');" depressed color="red lighten-5 red--text" rounded>close <v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
              <v-card-text>

            <v-list-item>
              <v-list-item-title class="border-bottom">
                <v-switch
                  label="Remind customer before the invoice is due"
                  v-model="remind_before_invoice_is_due"
                ></v-switch>
              </v-list-item-title>
          </v-list-item>

                  <template v-if="remind_before_invoice_is_due">
                      <v-row>
                          <v-col cols="12" class="mx-auto text-center">
                              <v-select
                                      outlined
                                      dense
                                      v-model="presetpreInvoiceDueReminderFrequency"
                                      :items="preInvoiceDueReminderPresets"
                                      label="Select Schedule"
                              ></v-select>
                              <v-text-field
                                      v-if="preInvoiceReminderCustomSelected"
                                      label="Frequency"
                                      placeholder="Frequency"
                                      type="number"
                                      v-model="preInvoiceReminderFrequency"
                                      outlined
                                      dense
                              ></v-text-field>
                              <v-select
                                      v-if="preInvoiceReminderCustomSelected"
                                      outlined
                                      dense
                                      v-model="preInvoiceReminderFrequencyType"
                                      :items="recurringPeriods"
                                      label="Frequency Type"
                              ></v-select>

                              <v-select
                                      outlined
                                      dense
                                      v-model="preInvoicePresetLimit"
                                      :items="['Unlimited', 'Custom']"
                                      label="Number of occurrence"
                                      hint="How many times do you want to remind the customer?"
                                      persistent-hint
                                      class="mt-4"
                              ></v-select>
                              <v-text-field
                                      v-if="preInvoiceReminderHasCustomLimit"
                                      label="Enter the number of reminders"
                                      type="number"
                                      v-model="preInvoiceReminderLimit"
                                      outlined
                                      hint="Enter the number of times this invoice can be reminded of before due"
                                      persistent-hint
                                      min="1"
                                      dense
                                      @change="preInvoicePresetLimit = !preInvoicePresetLimit ? 1 : preInvoicePresetLimit"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </template>



                  <v-list-item>
            <v-list-item-title class="border-bottom">
              <v-switch
                label="Remind customer when the invoice is due"
                v-model="remind_when_invoice_is_due"
              ></v-switch>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title class="border-bottom">
              <v-switch
                :label="`Remind customer when the invoice is early overdue`"
                v-model="remind_when_invoice_is_early_overdue"
              ></v-switch>
            </v-list-item-title>
            </v-list-item>
                  <v-list-item>

            <v-list-item-title class="border-bottom">
              <v-switch
                label="Remind customer when the invoice is late overdue"
                v-model="remind_when_invoice_is_late_overdue"
              ></v-switch>
            </v-list-item-title>
                  </v-list-item>

            <template v-if="remind_when_invoice_is_late_overdue">
              <v-row>
                <v-col cols="12" class="mx-auto text-center">
                  <v-select
                    outlined
                    dense
                    v-model="presetpostInvoiceDueReminderFrequency"
                    :items="postInvoiceDueReminderPresets"
                    label="Select Schedule"
                  ></v-select>
                  <v-text-field
                    v-if="postInvoiceReminderCustomSelected"
                    label="Frequency"
                    placeholder="Frequency"
                    type="number"
                    v-model="postInvoiceReminderFrequency"
                    outlined
                    dense
                  ></v-text-field>
                  <v-select
                    v-if="postInvoiceReminderCustomSelected"
                    outlined
                    dense
                    v-model="postInvoiceReminderFrequencyType"
                    :items="recurringPeriods"
                    label="Frequency Type"
                  ></v-select>

                  <v-select
                    outlined
                    dense
                    v-model="postInvoicePresetLimit"
                    :items="['Unlimited', 'Custom']"
                    label="Number of occurrence"
                    hint="How many times do you want to remind the customer?"
                    persistent-hint
                    class="mt-4"
                  ></v-select>
                  <v-text-field
                    v-if="postInvoiceReminderHasCustomLimit"
                    label="Enter the number of reminders"
                    type="number"
                    v-model="postInvoiceReminderLimit"
                    outlined
                    hint="Enter the number of times the customer should be reminded of this invoice when it's late overdue"
                    persistent-hint
                    min="1"
                    dense
                    @change="postInvoicePresetLimit = !postInvoicePresetLimit ? 1 : postInvoicePresetLimit"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>


              <v-snackbar
                      v-model="error_message"
                      color="error"
              >

                  {{error_msg}}

              </v-snackbar>
              <v-snackbar
                      v-model="success_message"
              >

                  {{success_msg}}

              </v-snackbar>

              </v-card-text>

              <v-card-actions>
                  <!-- v-if="remind_before_invoice_is_due || remind_when_invoice_is_due || remind_when_invoice_is_early_overdue || remind_when_invoice_is_late_overdue" -->
                  <v-btn

                          @click="saveReminder"
                          dark
                          block
                          rounded
                          depressed
                          :loading="progress"
                          large
                          color="blue darken-4"
                  >{{ $t("invoice.apply_reminder") }}</v-btn>

              </v-card-actions>
          </v-card>

</template>
<script>
export default {
  data() {
    return {
        progress:false,
      error_message: false,
      error_msg: '',
      success_message: false,
      success_msg: '',
      preInvoiceFrequency: '',
      remind_before_invoice_is_due: false,
      preInvoiceReminderLimit: 0,
      preInvoiceReminderHasCustomLimit: false,
      presetpreInvoiceDueReminderFrequency: "Daily",
      preInvoiceDueReminderPresets: ["Daily", "Weekly", "Monthly", "Yearly", "Custom"],
      preInvoicePresetLimit: "Unlimited",
      preInvoiceReminderFrequency: 1,
      preInvoiceReminderFrequencyType: "days",
      preInvoiceReminderCustomSelected: false,

      remind_when_invoice_is_due: false,

      remind_when_invoice_is_early_overdue: false,

      postInvoiceFrequency: '',
      remind_when_invoice_is_late_overdue: false,
      postInvoiceReminderLimit: 0,
      postInvoiceReminderHasCustomLimit: false,
      presetpostInvoiceDueReminderFrequency: "Daily",
      postInvoiceDueReminderPresets: ["Daily", "Weekly", "Monthly","Yearly","Custom"],
      postInvoicePresetLimit: "Unlimited",
      postInvoiceReminderFrequency: 1,
      postInvoiceReminderFrequencyType: "days",
      postInvoiceReminderCustomSelected: false,
    }
  },
  methods:{
    triggerReminding() {
      const data = {
            beforeDue: {
              selected : this.remind_before_invoice_is_due,
              frequency: this.preInvoiceReminderFrequency,
              frequencyType: this.preInvoiceReminderFrequencyType,
              limit: this.preInvoiceReminderLimit
            },
            whenDue: {
              selected: this.remind_when_invoice_is_due
            },
            earlyOverDue: {
              selected: this.remind_when_invoice_is_early_overdue
            },
            lateOverDue: {
              selected: this.remind_when_invoice_is_late_overdue,
              frequency: this.postInvoiceReminderFrequency,
              frequencyType: this.postInvoiceReminderFrequencyType,
              limit: this.postInvoiceReminderLimit
            }
      }
      if (this.remind_before_invoice_is_due || this.remind_when_invoice_is_due || this.remind_when_invoice_is_early_overdue
        || this.remind_when_invoice_is_late_overdue) {
          this.$emit("remind", data);
        } else {
          this.remind_before_invoice_is_due = this.remind_when_invoice_is_due
            = this.remind_when_invoice_is_early_overdue = this.remind_when_invoice_is_late_overdue = false;
            this.$emit("clearReminder", data);
        }
    },
    saveReminder() {
    if (!this.$store.state.selected_invoice.customer.business_email) {
        this.error_msg = "The customer must have email attached to send reminders";
        this.error_message = true;
        return;
    }


     const data = {
            beforeDue: {
              selected : this.remind_before_invoice_is_due,
              frequency: this.preInvoiceReminderFrequency,
              frequencyType: this.preInvoiceReminderFrequencyType,
              limit: this.preInvoiceReminderLimit
            },
            whenDue: {
              selected: this.remind_when_invoice_is_due
            },
            earlyOverDue: {
              selected: this.remind_when_invoice_is_early_overdue
            },
            lateOverDue: {
              selected: this.remind_when_invoice_is_late_overdue,
              frequency: this.postInvoiceReminderFrequency,
              frequencyType: this.postInvoiceReminderFrequencyType,
              limit: this.postInvoiceReminderLimit
            }
      }
    let reminders = [];
    if (data.beforeDue.selected) {
        reminders.push({
            reminder_type: 'beforedue',
            frequency: data.beforeDue.frequency,
            frequency_type: data.beforeDue.frequencyType,
            limit: data.beforeDue.limit
        });
    }
    if (data.whenDue.selected) reminders.push({reminder_type: 'whendue'});
    if (data.earlyOverDue.selected) reminders.push({reminder_type: 'earlyoverdue'});
    if (data.lateOverDue.selected) {
    reminders.push({
        reminder_type: 'lateoverdue',
        frequency: data.lateOverDue.frequency,
        frequency_type: data.lateOverDue.frequencyType,
        limit: data.lateOverDue.limit
        });
    }


    if (!reminders.length){

        this.error_msg = "No reminder selected, please select at least one reminder";
        this.error_message = true;
        return;
    }
    this.progress = true;

    axios.post('/api/setinvoicereminder', {
      reminders: JSON.stringify(reminders), invoice_id: this.$store.state.selected_invoice.id
      })
            .then(res => {
            this.$emit('close_dialog');
            this.$emit('success');
            this.$emit('get_updated_invoice');
            this.success_message = 'Reminder set successfully!';
            this.success_msg = true;
                this.progress = false;

            })
            .catch(err => {
              this.$emit('close_dialog');
              this.error_msg = err.response.data.message;
              this.error_message = true;
                this.progress = false;

            })
    }
  },
  watch: {
     presetLimit(val) {
      if (val === "Custom") {
        this.hasCustomLimit = true;
        this.limit = 1;
        return;
      }
      //set limit to 0 signifying unlimited
      this.limit = 0;
      this.hasCustomLimit = false;
    },
    preInvoicePresetLimit(val) {
       if (val === "Custom") {
        this.preInvoiceReminderHasCustomLimit = true;
        this.preInvoiceReminderLimit = 1;
        return;
      }
      //set limit to 0 signifying unlimited
      this.limit = 0;
      this.preInvoiceReminderHasCustomLimit = false;
    },
    postInvoicePresetLimit(val) {
       if (val === "Custom") {
        this.postInvoiceReminderHasCustomLimit = true;
        this.postInvoiceReminderLimit = 1;
        return;
      }
      //set limit to 0 signifying unlimited
      this.limit = 0;
      this.postInvoiceReminderHasCustomLimit = false;
    },
    presetFrequency(val) {
      if (val === "Custom") {
        this.isCustomPresetSelected = true;
        return;
      }
      const map = {
        Daily: "days",
        Weekly: "weeks",
        Monthly: "months",
        Yearly: "years",
      };
      this.frequencyType = map[val];
      this.isCustomPresetSelected = false;
    },
    presetpreInvoiceDueReminderFrequency(val) {
      if (val === "Custom") {
        this.preInvoiceReminderCustomSelected = true;
        return;
      }
      const map = {
        Daily: "days",
        Weekly: "weeks",
        Monthly: "months",
        Yearly: "years",
      };
      this.preInvoiceReminderFrequencyType = map[val];
      this.preInvoiceReminderCustomSelected = false;
    },
     presetpostInvoiceDueReminderFrequency(val) {
      if (val === "Custom") {
        this.postInvoiceReminderCustomSelected = true;
        return;
      }
      const map = {
        Daily: "days",
        Weekly: "weeks",
        Monthly: "months",
        Yearly: "years",
      };
      this.postInvoiceReminderFrequencyType = map[val];
      this.postInvoiceReminderCustomSelected = false;
    },
  }
}
</script>
