<template>
  <span>
    <v-dialog
      v-model="$store.state.show_share_sheet"
      width="500"
      transition="scroll-y-reverse-transition"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      v-if="!$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title>
          <v-btn small v-if="step > 1" color="blue" text @click="step = 1"
            ><v-icon>mdi-arrow-left</v-icon>back</v-btn
          >
          <h2 class="font-weight-light">Share invoice</h2>
          <v-spacer></v-spacer>
          <v-btn
            @click="$store.state.show_share_sheet = false"
            depressed
            rounded
            small
            color="red lighten-5 red--text"
            >close<v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <copy-bottun
                  :label="invoice.link"
                  :content="invoice.link"
                  :uuid="invoice.uuid"
                ></copy-bottun>
                <v-btn
                  :href="
                    $store.state.URL +
                    'viewinvoice/' +
                    $store.state.selected_invoice.uuid
                  "
                  target="_blank"
                  rounded
                  depressed
                  color="blue"
                  text
                  small
                  >Preview({{ invoice.invoice_number }})<v-icon small
                    >mdi-arrow-top-right</v-icon
                  ></v-btn
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-stepper style="box-shadow: none" v-model="step" class="pa-0">
            <v-stepper-items class="pa-0">
              <v-stepper-content step="1" class="pa-0">
                <v-list>
                  <v-list-item @click="step = 2">
                    <v-list-item-icon>
                      <v-icon dense large>mdi-email-check-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Share via email</v-list-item-title>
                      <v-list-item-subtitle
                        >Share this invoice with some
                        emails</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon class="text--disabled">mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item @click="step = 3">
                    <v-list-item-icon>
                      <v-icon dense large>mdi-cellphone-message</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >Share via SMS & WhatsApp</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Share this invoice via SMS &
                        WhatsApp</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon class="text--disabled">mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0">
                <sendinvoice-mail-component></sendinvoice-mail-component>
              </v-stepper-content>
              <v-stepper-content step="3" class="pa-0">
                <send-s-m-s-c-omponent
                  :invoice="invoice"
                ></send-s-m-s-c-omponent>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-else v-model="$store.state.show_share_sheet" scrollable>
      <v-card>
        <v-card-title>
          <v-btn small v-if="step > 1" color="blue" text @click="step = 1"
            ><v-icon>mdi-arrow-left</v-icon>back</v-btn
          >
          <h2 class="font-weight-light">Share invoice</h2>
          <v-spacer></v-spacer>
          <v-btn
            @click="$store.state.show_share_sheet = false"
            depressed
            rounded
            small
            color="red lighten-5 red--text"
            v-if="step == 1"
            >close<v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <copy-bottun
                  :label="invoice.link"
                  :content="invoice.link"
                  :uuid="invoice.uuid"
                ></copy-bottun>
                <v-btn
                  :href="
                    $store.state.URL +
                    'viewinvoice/' +
                    $store.state.selected_invoice.uuid
                  "
                  target="_blank"
                  rounded
                  depressed
                  color="blue"
                  text
                  small
                  >Preview<v-icon small>mdi-arrow-top-right</v-icon></v-btn
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-stepper style="box-shadow: none" v-model="step" class="pa-0">
            <v-stepper-items class="pa-0">
              <v-stepper-content step="1" class="pa-0">
                <v-list>
                  <v-list-item @click="step = 2">
                    <v-list-item-icon>
                      <v-icon dense large>mdi-email-check-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Share via email</v-list-item-title>
                      <v-list-item-subtitle
                        >Share this invoice with some
                        emails</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon class="text--disabled">mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item @click="step = 3">
                    <v-list-item-icon>
                      <v-icon dense large>mdi-cellphone-message</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        >Share via SMS & WhatsApp</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >Share this invoice via SMS &
                        WhatsApp</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon class="text--disabled">mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-stepper-content>
              <v-stepper-content step="2" class="pa-0">
                <sendinvoice-mail-component></sendinvoice-mail-component>
              </v-stepper-content>
              <v-stepper-content step="3" class="pa-0">
                <send-s-m-s-c-omponent
                  :invoice="invoice"
                ></send-s-m-s-c-omponent>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </span>
</template>

<script>
import CopyBottun from "../patials/copyBottun";
import SendSMSCOmponent from "./SendSMSCOmponent";
import SendinvoiceMailComponent from "./SendinvoiceMailComponent";

export default {
  name: "shareSheetComponent",
  components: { SendinvoiceMailComponent, SendSMSCOmponent, CopyBottun },
  data() {
    return {
      step: 1,
    };
  },
  computed: {
    invoice() {
      return this.$store.state.selected_invoice;
    },
  },
};
</script>

<style scoped></style>
