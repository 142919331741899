<template>
  <div>
    <!--    <v-banner v-if="!subscribed" class="p-4 bg-primary-gradient text-white text-center" rounded-->
    <!--              style="height: 450px; border-bottom-right-radius: 50% !important; border-bottom-left-radius: 50% !important; ">-->
    <!--      <h1 class="font-weight-light">Software only plans</h1>-->
    <!--      These subscription plans are for premium features on the application like POS, Payroll, Quotes, Multiple users-->

    <!--    </v-banner>-->

    <v-row>
      <v-col cols="12" sm="10" class="mx-auto">
        <v-radio-group v-model="plan" :mandatory="false">
          <v-row dense>
            <v-col v-if="plans.length" cols="12" sm="4">
              <v-card
                :class="
                  plan === plans[0].name.toLowerCase()
                    ? 'active card-hover'
                    : 'card-hover'
                "
                flat
                @click="
                  plan = plans[0].name.toLowerCase();
                  selected = true;
                  compute_amount();
                  resetAddOns();
                "
              >
                <v-skeleton-loader
                  :loading="progress"
                  class="mx-auto"
                  max-width="300"
                  type="list-item-avatar, list-item@15"
                >
                  <v-card-title>

                    <v-radio class="d-none" value="starter"></v-radio>
                    <h4 class="font-weight-bolder mt-5">
                      Basic - {{ currency }}{{ basic_amount | toMoney }} Monthly
                    </h4>
                  </v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on">
                        <v-list class="mt-4">
                          <v-list-item
                            v-for="benefit in basic"
                            :key="benefit.benefit"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ benefit.benefit }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                color="green lighten-5"
                                fab
                                x-small
                                depressed
                                v-if="benefit.checked1"
                              >
                                <v-icon x-small color="green">mdi-check</v-icon>
                              </v-btn>
                              <v-btn
                                color="red lighten-5"
                                fab
                                x-small
                                depressed
                                v-else
                              >
                                <v-icon x-small color="red">mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </template>
                    <span>Click to select this plan</span>
                  </v-tooltip>

                  <v-card-actions>
                    <v-btn
                      block
                      class="btn btn-primary"
                      dark
                      depressed
                      x-large
                      rounded
                      >Select</v-btn
                    >
                  </v-card-actions>
                </v-skeleton-loader>
              </v-card>
            </v-col>

            <v-col v-if="starter_active" cols="12" sm="4">
              <v-card
                :class="plan == 'starter' ? 'active card-hover' : 'card-hover'"
                flat
                @click="
                  plan = 'starter';
                  selected = true;
                  compute_amount();
                "
              >
                <v-skeleton-loader
                  :loading="progress"
                  class="mx-auto"
                  max-width="300"
                  type="list-item-avatar, list-item@15"
                >
                  <v-card-title>
                    <v-radio class="d-none" value="starter"></v-radio>
                    <h4 class="font-weight-bolder mt-5">
                      Starter - {{ currency
                      }}{{ starter_amount | toMoney }} Monthly
                    </h4>
                  </v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on">
                        <v-list class="mt-4">
                          <v-list-item
                            v-for="benefit in starter"
                            :key="benefit.benefit"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ benefit.benefit }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                color="green lighten-5"
                                fab
                                x-small
                                depressed
                                v-if="benefit.checked2"
                              >
                                <v-icon x-small color="green">mdi-check</v-icon>
                              </v-btn>
                              <v-btn
                                color="red lighten-5"
                                fab
                                x-small
                                depressed
                                v-else
                              >
                                <v-icon x-small color="red">mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </template>
                    <span>Click to select this plan</span>
                  </v-tooltip>

                  <v-card-actions>
                    <v-btn
                      block
                      class="btn btn-primary"
                      dark
                      depressed
                      x-large
                      rounded
                      >Select</v-btn
                    >
                  </v-card-actions>
                </v-skeleton-loader>
              </v-card>
            </v-col>

            <v-col v-if="enterprise_active && plans[2]" cols="12" sm="4">
              <v-card
                :class="
                  plan === plans[2].name.toLowerCase()
                    ? 'active card-hover'
                    : 'card-hover'
                "
                flat
                @click="
                  plan = plans[2].name.toLowerCase();
                  selected = true;
                  compute_amount();
                  resetAddOns();
                "
              >
                <v-skeleton-loader
                  :loading="progress"
                  class="mx-auto"
                  max-width="300"
                  type="list-item-avatar, list-item@15"
                >
                  <v-card-text
                    class="bg-success-gradient"
                    style="border-radius: 15px"
                  >
                    <small class="white--text"
                      >Recommended
                      <v-icon dark>mdi-check-circle</v-icon>
                    </small>

                    <v-radio class="d-none" value="enterprise"></v-radio>
                    <h4 class="font-weight-bolder white--text">
                      {{ plans[2].name }} - {{ currency
                      }}{{ enterprise_amount | toMoney }} Monthly
                    </h4>
                  </v-card-text>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on">
                        <v-list class="mt-4">
                          <v-list-item
                            v-for="benefit in enterprise"
                            :key="benefit.benefit"
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ benefit.benefit }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                color="green lighten-5"
                                fab
                                x-small
                                depressed
                                v-if="benefit.checked3"
                              >
                                <v-icon x-small color="green">mdi-check</v-icon>
                              </v-btn>
                              <v-btn
                                color="red lighten-5"
                                fab
                                x-small
                                depressed
                                v-else
                              >
                                <v-icon x-small color="red">mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </template>
                    <span>Click to select this plan</span>
                  </v-tooltip>
                  <v-btn
                    block
                    class="btn btn-success"
                    dark
                    depressed
                    x-large
                    rounded
                    >Select</v-btn
                  >
                </v-skeleton-loader>
              </v-card>
            </v-col>

            <!--            <v-col cols="12" sm="2" class="text-center">-->

            <!--                <v-btn v-if="(venture_active || pro_active || plus_active)" x-large @click="$vuetify.goTo('#more_plans')" rounded color="blue darken-4" dark style="margin-top: 300px" class="d-md-block d-none">-->
            <!--                    More <v-icon>mdi-arrow-down</v-icon>-->
            <!--                </v-btn>-->
            <!--            </v-col>-->
          </v-row>
        </v-radio-group>
      </v-col>

      <v-col cols="12" sm="10" class="mx-auto">
        <v-banner
          v-if="venture_active || pro_active || plus_active"
          class="p-4 bg-success-gradient text-white text-center"
          rounded
          style="
            height: 450px;
            border-bottom-right-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
          "
        >
          <h1 class="font-weight-light">Software + virtual support plans</h1>
          These subscription plans give you additional value like virtual
          professional support including all the premium features of built
          <v-alert class="mt-2" dark type="info">
            <strong class="font-weight-bolder">Note:</strong> All the support
            services are limited to transactions occurring within ONLY MONTHS
            subscribed for.
          </v-alert>
        </v-banner>

        <v-radio-group
          id="more_plans"
          v-model="plan"
          :mandatory="false"
          class="m-3"
          style="margin-top: -17rem !important"
        >
          <v-row>
            <v-col v-if="venture_active" cols="12" sm="4">
              <v-card
                :class="plan == 'venture' ? 'active card-hover' : 'card-hover'"
                flat
                @click="
                  plan = 'venture';
                  selected = true;
                  compute_amount();
                  resetAddOns();
                "
              >
                <v-skeleton-loader
                  :loading="progress"
                  class="mx-auto"
                  max-width="300"
                  type="list-item-avatar, list-item@8"
                >
                  <v-card-title>
                    <v-radio class="d-none" value="venture"></v-radio>
                    <h4 class="font-weight-bolder">
                      Venture - {{ currency
                      }}{{ venture_amount | toMoney }} Monthly
                    </h4>
                  </v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on">
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                All Packages on Enterprise
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Virtual Bookkeeper
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-actions>
                        <strong>Bookkeeper responsible for: </strong><br />
                        <ul>
                          <li>
                            Providing onboarding support by customizing charts
                            of accounts and entering opening balances<sup
                              class="text-danger"
                              >*</sup
                            >
                          </li>

                          <li>Categorize transactions</li>
                          <li>Reconcile accounts</li>
                          <li>Generate detailed reports</li>
                          <li>Close your books each month</li>
                        </ul>
                      </v-card-actions>
                    </template>
                    <span>Click to select this plan</span>
                  </v-tooltip>
                  <p class="w-100 p-2">
                    <sup class="text-danger font-weight-black">*</sup> You are
                    responsible for providing opening balances (if any) to be
                    entered.
                  </p>
                  <v-btn rounded x-large block class="btn btn-primary" dark depressed
                    >Select</v-btn
                  >
                </v-skeleton-loader>
              </v-card>
            </v-col>


            <v-col v-if="pro_active" cols="12" sm="4">
              <v-card
                :class="plan == 'pro' ? 'active card-hover' : 'card-hover'"
                flat
                @click="
                  plan = 'pro';
                  selected = true;
                  compute_amount();
                  resetAddOns();
                "

                :disabled="business.country.name !== 'Ghana'"
              >
                <v-skeleton-loader
                  :loading="progress"
                  class="mx-auto"
                  max-width="300"
                  type="list-item-avatar, list-item@7"
                >
                  <v-card-title>
                    <v-radio class="d-none" value="pro"></v-radio>
                    <h4 class="font-weight-bolder">
                      Pro - {{ currency }}{{ pro_amount | toMoney }} Monthly
                    </h4>
                  </v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on">
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                All Packages on Enterprise
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                All packages on venture
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>

                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Tax Support
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>

                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-actions>
                        <strong>Tax support include: </strong><br />
                        <ul>
                          <li>Filing &amp; Payment of Corporate tax</li>

                          <li>Filing &amp; Payment of VAT</li>
                          <li>Payment of Withholding taxes</li>
                          <li>Tax advisory</li>
                        </ul>
                      </v-card-actions>
                    </template>
                    <span>Click to select this plan</span>
                  </v-tooltip>

                  <v-btn rounded block class="btn btn-primary" dark depressed x-large
                    >Select</v-btn
                  >
                </v-skeleton-loader>
              </v-card>
            </v-col>

            <v-col v-if="plus_active" cols="12" sm="4">
              <v-card
                :class="plan == 'plus' ? 'active card-hover' : 'card-hover'"
                flat
                @click="
                  plan = 'plus';
                  selected = true;
                  compute_amount();
                  resetAddOns();
                "

                :disabled="business.country.name !== 'Ghana'"
              >
                <v-skeleton-loader
                  :loading="progress"
                  class="mx-auto"
                  max-width="300"
                  type="list-item-avatar, list-item@10"
                >
                  <v-card-title>
                    <v-radio class="d-none" value="plus"></v-radio>
                    <h3 class="font-weight-bolder">
                      Plus - {{ currency }}{{ plus_amount | toMoney }} Monthly
                    </h3>
                  </v-card-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-card-text v-bind="attrs" v-on="on">
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                All Packages on Enterprise
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                All packages on venture
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Tax Support
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Payroll Support
                              </v-list-item-title>
                            </v-list-item-content>

                            <v-btn
                              color="green lighten-5"
                              fab
                              x-small
                              depressed
                            >
                              <v-icon x-small color="green">mdi-check</v-icon>
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-card-text>

                      <v-card-actions>
                        <strong>Payroll support include: </strong><br />
                        <ul>
                          <li>Monthly payroll preparation</li>

                          <li>Payment of SSNIT, Tier 2 &amp; 3</li>
                          <li>Payment of employee taxes (PAYE)</li>
                          <li>Employee compensation advisory</li>

                          <li>Payroll reconciliation</li>
                        </ul>
                      </v-card-actions>
                    </template>
                    <span>Click to select this plan</span>
                  </v-tooltip>

                  <v-btn block class="btn btn-primary" dark depressed x-large rounded
                    >Select</v-btn
                  >
                </v-skeleton-loader>
              </v-card>
            </v-col>
          </v-row>
        </v-radio-group>

        <v-col v-if="false" class="mx-auto" cols="12" sm="6">
          <v-alert type="error">
            <h4>Sorry, we do not support these packages in your country</h4>
          </v-alert>
        </v-col>
      </v-col>
    </v-row>

    <!--    <v-alert class="text-center" type="info">-->
    <!--      You have already subscribed to the-->
    <!--      <strong>{{ $store.state.user.user_infor.current_business.subscription.name }}</strong> plan please-->
    <!--      <v-btn dark small text to="/subscriptions">head to</v-btn>-->
    <!--      your billing section to manage your subscriptions-->
    <!--    </v-alert>-->

    <v-bottom-sheet v-model="selected" persistent scrollable width="100%">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="red lighten-5 red--text"
            depressed
            rounded
            small
            @click="
              selected = false;
              plan = '';
            "
            >Close
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-center pa-5">
          <v-progress-circular
            v-if="progress"
            class="ma-3"
            color="blue darken-4"
            indeterminate
            size="100"
          ></v-progress-circular>

          <v-row v-if="!progress">
            <v-col v-if="promotions.length" cols="12" sm="4">
              <subcrpiton-promotions
                :promotions="promotions"
              ></subcrpiton-promotions>
            </v-col>


            <v-col v-else cols="12" sm="2"> </v-col>

            <v-col class="mt-4" cols="12" sm="4">
              <h3 class="font-weight-light text--primary text-h4">
                Go {{ plan }} for {{ currency }}{{ amount | toMoney }}/Month
              </h3>

              <h5
                v-if="
                  !['basic', 'starter'].includes(plan.toLowerCase()) &&
                  this.plan != 'basic' &&
                  employeesExtension &&
                  employeesExtension.amount > 0
                "
              >
                <strong class="text-h5">Payroll Add-ons:</strong>
                <p>
                  <strong>Number of Employees:</strong
                  >{{ employeesExtension.totalNumber }}
                </p>
                <p>
                  <strong>Cost:</strong>{{ currency
                  }}{{ employeesExtension.amount | toMoney }}
                </p>
              </h5>

              <h5
                v-if="
                  !['basic', 'starter'].includes(plan.toLowerCase()) &&
                  this.plan != 'basic' &&
                  visithours &&
                  visithours.amount > 0
                "
              >
                <strong class="text-h5">Onsite visits:</strong>
                <p>
                  <strong>Number of Hours:</strong>{{ visithours.hours }} Hours
                </p>
                <p>
                  <strong>Per Week:</strong
                  >{{ visithours.visits_per_week }} Times
                </p>
                <p>
                  <strong>Per Month:</strong
                  >{{ visithours.visits_per_month }} Times
                </p>
                <p>
                  <strong>Cost:</strong>{{ currency
                  }}{{ visithours.amount | toMoney }}
                </p>
              </h5>


              <div v-if="!coupon_applied" class="font-weight-light">
                <h1>Amount Due: {{ currency }}{{ finalAmount | toMoney }}</h1>
                <h1 v-if="isEligibleForDiscount && promoPlan !== 'basic'">
                  Discount: {{ currency }}{{ packageDiscount | toMoney }}
                </h1>
                <h1 v-if="isEligibleForDiscount && promoPlan !== 'basic'">
                  Total: {{ currency }}{{ discountedPrice | toMoney }}
                </h1>
              </div>
              <div v-else>
                <h5
                  v-if="discount > 0"
                  class="font-weight-light"
                  style="text-decoration: line-through"
                >
                  Amount Due: {{ currency }}{{ full_amount | toMoney }}
                </h5>

                <h5 class="font-weight-light">Discount: {{ discount }}%</h5>

                <h1 class="font-weight-black">
                  Amount Due: {{ currency }}{{ finalAmount | toMoney }}
                </h1>
              </div>

              <v-select
                v-model="billing_cycle"
                :items="billing_cycles"
                label="Billing cycle"
                outlined
                @change="compute_amount"
              >
              </v-select>

              <!--              <paystack-->
              <!--                  :amount="send_amount*100"-->
              <!--                  :email="$store.state.user.user_infor.email"-->
              <!--                  :paystackkey="$store.state.paystackkey"-->
              <!--                  :callback="send_success"-->
              <!--                  :embed="false"-->
              <!--                  :channels="channels"-->
              <!--                  :reference="reference"-->
              <!--                  :close="close()"-->
              <!--                  :currency="$store.state.user.user_infor.current_business.currency.code"-->
              <!--              >-->

              <!--                <v-btn depressed large rounded :disabled="!billing_cycle" dark color="green">-->
              <!--                  {{ business.country.code === 'GH' ? 'Pay with card' : 'proceed to checkout' }}-->
              <!--                </v-btn>-->

              <!--              </paystack>-->

              <v-btn
                v-if="billing_cycle"
                :loading="loading_amount"
                class="m-2"
                color="green"
                dark
                depressed
                exact
                rounded
                x-large
                @click="
                  init_payment(
                    finalAmount,
                    plan,
                    billing_cycle,
                    coupon_applied,
                    coupon_code,
                    progress,
                    complete
                  )
                "
                >{{
                  ["GH", "RW"].includes(business.country.code)
                    ? "Pay with card"
                    : "proceed to checkout"
                }}
              </v-btn>

              <!--                       <v-btn-->
              <!--                               depressed-->
              <!--                               large-->
              <!--                               rounded-->
              <!--                               exact-->
              <!--                               :loading="loading_amount"-->
              <!--                               dark-->
              <!--                               v-if="billing_cycle"-->
              <!--                               color="green"-->
              <!--                               @click="init_payment(amount_due,plan,billing_cycle,coupon_applied,coupon_code,progress,complete)"-->
              <!--                               class="m-2"-->

              <!--                       >{{business.country.code==='GH' ? 'Pay with card' : 'proceed to checkout'}}</v-btn>-->
              <!--                        -->

              <v-btn
                v-if="
                  billing_cycle &&
                  ['GH', 'KE', 'RW'].includes(business.country.code)
                "
                class="m-1"
                color="blue darken-4"
                dark
                depressed
                rounded
                x-large
                @click="momoCheckout = true"
                >Pay with
                {{
                  ["GH", "RW"].includes(business.country.code)
                    ? "mobile money"
                    : "Mpesa"
                }}
              </v-btn>

              <v-btn
                v-if="billing_cycle"
                color="blue"
                rounded
                text
                @click="coupon_dialog = true"
                >Apply coupon</v-btn
              >

              <v-progress-circular
                v-if="loading"
                color="blue darken-4"
                indeterminate
                size="20"
              ></v-progress-circular>
            </v-col>

            <v-col cols="12" sm="5">
              <v-card color="green" flat>
                <v-card-title class="text-center border-bottom">
                  <h2 class="text-center w-100 white--text">
                    <v-icon class="d-block" dark style="font-size: 50px"
                      >mdi-puzzle-outline</v-icon
                    >
                    Add-ons to your plan
                  </h2>
                </v-card-title>

                <v-card-text
                  v-if="!['basic', 'starter'].includes(plan.toLowerCase())"
                  class="mt-3"
                >
                  <subscription-extension-component
                    v-if="selected"
                    :cost-per-hour="costPerHour"
                    :current-employees-count="currentEmployeeCount"
                    :cycle="billing_cycle"
                    :plan="plan"
                    :rate="selectPlan.rate.rate"

                    :currency="currency"
                    :unit_cost="extraEmployeeCost"
                    @employeeChanged="(data) => (employeesExtension = data)"
                    @visitsChanged="(data) => (visithours = data)"
                  ></subscription-extension-component>
                </v-card-text>
                <v-card-text v-else>
                  <h2 class="white--text">
                    This plan has no add-on options, add-ons are only available
                    on Enterprise,Venture,Pro and Plus
                  </h2>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <v-dialog
      v-model="momoCheckout"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="font-weight-light">
          Pay with
          {{
            ["GH", "RW"].includes(business.country.code)
              ? "Mobile Money"
              : "Mpesa"
          }}
          <v-spacer></v-spacer>
          <v-btn
            color="red lighten-5 red--text"
            depressed
            rounded
            @click="momoCheckout = false"
            >close
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <checkout
          :account_id="0"
          :amount="finalAmount"
          :discountedPrice="discountedPrice"
          :isEligibleForDiscount="isEligibleForDiscount"
          :record_id="billing_cycle"
          :record_type="'subscription-' + plan"
          @paymentComplete="send_success"
        ></checkout>
      </v-card>
    </v-dialog>

    <v-dialog v-model="complete" persistent width="300">
      <v-card>
        <v-card-text class="text-center">
          <v-icon class="text-success" x-large>mdi-rocket</v-icon>

          <h3 class="font-weight-light">You have successfully subscribed.</h3>
          <h4 class="font-weight-light">
            Subscription starts: {{ startdate }} and ends on {{ enddate }}
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            color="green"
            dark
            depressed
            large
            rounded
            @click="finish_sub()"
            >ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="error_message" :timeout="-1" color="red">
      {{ error_msg }}
      <v-btn dark text @click="send_success">Retry</v-btn>
    </v-snackbar>

    <v-dialog v-model="coupon_dialog" persistent width="400">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            color="red lighten-5 red--text"
            depressed
            rounded
            @click="coupon_dialog = false"
            >Close
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="coupon_form">
            <v-text-field
              v-if="!coupon_success"
              v-model="coupon_code"
              :rules="code_rules"
              label="Coupon Code *"
              outlined
            ></v-text-field>

            <v-alert
              v-if="coupon_success"
              transition="scroll-y-transition"
              type="success"
            >
              <h4>
                Congratulations, you have won a discount of {{ discount }}% off
                on your desired plan
              </h4>
            </v-alert>

            <v-alert v-if="coupon_error" type="error">{{
              coupon_error_message
            }}</v-alert>

            <v-btn
              v-if="!coupon_success"
              :disabled="coupon_progress"
              block
              color="green"
              dark
              depressed
              rounded
              x-large
              @click="test_coupon"
            >
              {{ coupon_progress ? "Fingers Crossed..." : "Apply" }}
            </v-btn>

            <v-btn
              v-else
              block
              color="green"
              dark
              depressed
              rounded
              x-large
              @click="redeem_discount"
            >
              Redeem Discount
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import SubcrpitonPromotions from "./patials/SubcrpitonPromotions";
import Checkout from "./MOMO/Checkout";
import paystack from "vue-paystack";
import axios from "axios";
import SubscriptionExtensionComponent from "./patials/SubscriptionExtensionComponent";

export default {
  components: {
    SubscriptionExtensionComponent,
    Checkout,
    SubcrpitonPromotions,
    paystack,
  },
  amount_due: 0,
  name: "plansComponent",
  data() {
    return {
      extraEmployeeCost: 0.5,
      costPerHour: 5,
      currentEmployeeCount: 0,
      momoCheckout: false,
      promotions: [],
      country: "",
      packages: [
        "basic",
        "starter",
        "enterprise",
        "premium",
        "venture",
        "pro",
        "plus",
      ],
      starter_active: true,
      benefits: [
        {
          benefit: "50 Invoices per month",
          checked1: true,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Unlimited Invoices",
          checked1: true,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Unlimited Expenses",
          checked1: true,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Inventory Management",
          checked1: true,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Unlimited Quotes",
          checked1: true,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Multiple Currencies",
          checked1: false,
          checked2: false,
          checked3: true,
        },
        {
          benefit: "Custom Invoice Settings",
          checked1: false,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Multiple Users",
          checked1: false,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "File Attachment",
          checked1: false,
          checked2: false,
          checked3: true,
        },
        {
          benefit: "Transactions Tagging",
          checked1: false,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "POS",
          checked1: false,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Chart of Accounts",
          checked1: false,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Bank Reconciliation",
          checked1: false,
          checked2: false,
          checked3: true,
        },
        {
          benefit: "Invoice View Notifications",
          checked1: false,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Manual Journal",
          checked1: false,
          checked2: true,
          checked3: true,
        },
        {
          benefit: "Payroll",
          checked1: false,
          checked2: false,
          checked3: true,
        },
        {
          benefit: "Payroll(Limited to 5 employees)",
          checked1: false,
          checked2: false,
          checked3: true,
        },
        {
          benefit: "Virtual Bookkeeper",
          checked1: false,
          checked2: false,
          checked3: false,
        },
      ],
      enterprise_active: true,
      venture_active: true,
      pro_active: true,
      plus_active: true,
      coupon_code: null,
      coupon_dialog: false,
      loading: false,
      loading_amount: false,
      complete: false,
      send_amount: 0,
      full_amount: 0,
      coupon_error: false,
      coupon_error_message: "",
      amount_due: 0,
      basic_amount: 0,
      coupon_applied: false,
      coupon_success: false,
      discount: 20,
      starter_amount: 0,
      enterprise_amount: 0,
      pro_amount: 0,
      venture_amount: 0,
      plus_amount: 0,
      currency: "",
      base_currency: "",
      coupon_progress: false,
      billing_cycle: 3,
      subscribed: false,
      visithours: {
        amount: 0,
        hours: 0,
        visits_per_week: 0,
        visits_per_month: 0,
      },
      employeesExtension: {
        amount: 0,
        defaultNumber: 5,
        extraNumber: 0,
        totalNumber: 5,
      },
      billing_cycles: [
        {

          text: "Every three months",
          value: 3,
        },
        {
          text: "Every four months",
          value: 4,
        },
        {
          text: "Every five months",
          value: 5,
        },
        {
          text: "Every six months",
          value: 6,
        },
        {
          text: "Every seven months",
          value: 7,
        },
        {
          text: "Every eight months",
          value: 8,
        },
        {
          text: "Every nine months",
          value: 9,
        },
        {
          text: "Every ten months",
          value: 10,
        },
        {
          text: "Every eleven months",
          value: 11,
        },
        {
          text: "Annually",
          value: 12,
        },
        {
          text: "Every two years",
          value: 24,
        },
        {
          text: "Every three years",
          value: 36,
        },
        {
          text: "Every four years",
          value: 48,
        },
      ],
      plan: "basic",
      success_message: false,
      success_msg: "",
      error_msg: "",
      error_message: false,
      selected: false,
      valid: true,
      plans: [],
      progress: false,
      subscribe_dialog: false,
      plan_name: "",
      plan_code: null,
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      business_name: "",
      date_menu: false,
      startdate: "",
      enddate: "",
      business_email_rules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      code_rules: [(value) => !!value || "Coupon code is Required."],
    };
  },
  computed: {
    finalAmount() {
      return (
        Number(this.amount_due) +
        Number(this.employeesExtension.amount) +
        Number(this.visithours.amount)
      );
    },

    basic() {
      const benefits = this.benefits.filter((benefit) => {
        if (
          benefit.benefit === "Unlimited Invoices" ||
          benefit.benefit === "Payroll(Limited to 5 employees)"
        ) {
          return false;
        }
        return true;
      });
      return benefits;
    },
    starter() {
      const benefits = this.benefits.filter((benefit) => {
        if (
          benefit.benefit === "50 Invoices per month" ||
          benefit.benefit === "Payroll(Limited to 5 employees)"
        ) {
          return false;
        }
        return true;
      });
      return benefits;
    },
    enterprise() {
      const benefits = this.benefits.filter((benefit) => {
        if (
          benefit.benefit === "50 Invoices per month" ||
          benefit.benefit === "Payroll"
        ) {
          return false;
        }
        return true;
      });
      return benefits;
    },
    promoPlan() {
      return localStorage.getItem("package");
    },
    isValidPromoPlan() {
      if (this.packages.includes(this.promoPlan)) {
        return true;
      }
      return false;
    },
    packageDiscount() {
      const softwarePlans = ["basic", "starter", "enterprise", "premium"];
      const virtualAccountantPlans = ["venture", "pro", "plus"];
      if (this.isValidPromoPlan) {
        if (softwarePlans.includes(this.promoPlan)) {

          const amount = 0.375 * this.finalAmount;
          return amount;
        }
        if (virtualAccountantPlans.includes(this.promoPlan)) {
          const amount = 0.2 * this.finalAmount;
          return amount;
        }
      }
      return 0;
    },
    discountedPrice() {
      return this.finalAmount - this.packageDiscount;
    },
    isEligibleForDiscount() {
      if (
        this.isValidPromoPlan &&
        this.plan === this.promoPlan &&
        this.billing_cycle > 5
      ) {
        return true;
      }
      return false;
    },
    selectPlan() {
      return this.plans.find(
        (plan) => plan.name.toLowerCase() === this.plan.toLowerCase()
      );
    },

    business() {
      return this.$store.state.user.user_infor.current_business;
    },
    channels() {
      return ["card", "bank"];
    },
    amount() {
      this.startdate = moment().format("Y-M-D");
      this.enddate = moment().add(this.billing_cycle, "months").format("Y-M-D");
      let price = 0;
      if (this.plan == "starter") {
        price = this.starter_amount;
      } else if (this.plan == "enterprise") {
        price = this.enterprise_amount;
      } else if (this.plan == "premium") {
        price = this.enterprise_amount;
      } else if (this.plan == "pro") {
        price = this.pro_amount;
      } else if (this.plan == "plus") {
        price = this.plus_amount;
      } else if (this.plan === "venture") {
        price = this.venture_amount;
      } else {
        price = this.basic_amount;
      }
      return price;
    },
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      let d = new Date();
      let timestamp = d.getTime();
      return "Subscription_" + timestamp + "" + text;
    },
  },
  methods: {

    basicBenefit(benefit) {
      if (
        benefit === "Unlimited Invoices" ||
        benefit === "Payroll(Limited to 5 employees)"
      ) {
        return false;
      }
      return true;
    },
    starterBenefit(benefit) {
      if (
        benefit === "50 Invoices per month" ||
        benefit === "Payroll(Limited to 5 employees)"
      ) {
        return false;
      }
      return true;
    },
    enterpriseBenefit(benefit) {
      if (benefit === "50 Invoices per month" || benefit === "Payroll") {
        return false;
      }
      return true;
    },
    resetAddOns() {
      this.visithours = {
        amount: 0,
        hours: 0,
        visits_per_week: 0,
        visits_per_month: 0,
      };

      this.employeesExtension = {
        amount: 0,
        defaultNumber: 5,
        extraNumber: 0,
        totalNumber: 5,
      };
    },
    getPromotions() {
      axios
        .get(
          "/api/subscriptionpromotions/" +
            this.$store.state.user.user_infor.current_business.country.id
        )
        .then((res) => {
          this.promotions = res.data;
        });
    },
    init_payment() {
      if (this.base_currency !== this.currency && !this.selectPlan.converted) {

        this.convertAndPay(this.currency, this.base_currency, this.finalAmount);
      } else {
        this.makePayment(
          this.finalAmount,
          this.plan,
          this.billing_cycle,
          this.coupon_applied,
          this.coupon_code
        );
      }
    },
    makePayment(amount_due, plan, billing_cycle, coupon_applied, coupon_code) {
      this.progress = true;

      // const formData = new FormData();
      //
      // formData.append("amount", amount_due);
      // formData.append("type", 'subscription-' + this.plan);
      // formData.append("type_id", this.billing_cycle);
      // formData.append("reference", this.reference);
      //
      const payload = {
        amount: this.isEligibleForDiscount && this.promoPlan !== "basic"
            ? this.discountedPrice
            : amount_due,
        type: "subscription-" + this.plan,
        type_id: this.billing_cycle,
        reference: this.reference,
        add_ons: {
          employee_extension: this.employeesExtension,

          onsit_visits: this.visithours,
        },
      };

      axios.post("/api/collection", payload).then((res) => {
        this.progress = false;

        this.$launchFlutterwave({
          tx_ref: res.data.reference,

          amount:
            this.isValidPromoPlan &&
            plan === this.promoPlan &&
            this.billing_cycle > 5 &&
            this.promoPlan !== "basic"
              ? this.discountedPrice
              : this.send_amount,
          country: this.business.country.code,
          currency: this.base_currency,
          payment_options: "card,mobilemoney,ussd",
          customer: {
            email: this.$store.state.user.user_infor.email,
            phonenumber: this.$store.state.user.user_infor.phone_number,
            name:
              this.$store.state.user.user_infor.first_name +
              " " +
              this.$store.state.user.user_infor.last_name,
            billing_cycle: this.billing_cycle,
            plan: plan,
          },
          callback: function (data) {},
          customizations: {
            title: `Subscribe to the ${plan} plan for ${this.base_currency} ${
              this.isEligibleForDiscount

                ? this.discountedPrice
                : this.amount_due
            }.00 every ${this.billing_cycle} months`,
            description:
              this.$store.state.user.user_infor.current_business.address,
            logo: "https://my.builtaccounting.com/img/app_name.png",
          },
        });
      });
    },
    get_plans() {
      this.progress = true;
      axios
        .get(
          "/api/subscriptionplans/" +
            this.$store.state.user.user_infor.current_business.country.id
        )
        .then((res) => {
          this.plans = res.data;

          if (res.data.length > 0) {
            this.country = res.data[0].country_code;

            this.basic_amount = res.data[0].amount;
            this.starter_amount = res.data[1].amount;
            this.enterprise_amount = res.data[2].amount;
            this.venture_amount = res.data[3].amount;
            this.pro_amount = res.data[4].amount;
            this.plus_amount = res.data[5].amount;
            this.currency = res.data[4].currency_code;
            this.base_currency = res.data[0].base_currency_code;
            this.starter_active = res.data[0].active === 1;
            this.enterprise_active = res.data[1].active === 1;
            this.venture_active = res.data[2].active === 1;
            this.pro_active = res.data[3].active === 1;
            this.plus_active = res.data[4].active === 1;
          }

          if (this.isValidPromoPlan) {
            this.plan = this.promoPlan;
            this.selected = true;
            this.billing_cycle = 6;
            this.compute_amount();

          }
          this.progress = false;
        });
    },
    redeem_discount() {
      const discount_amount = (this.discount / 100) * this.full_amount;

      this.amount_due = this.full_amount - discount_amount;
      this.send_amount = this.amount_due;
      this.coupon_applied = this.discount > 0;
      this.coupon_success = false;
      this.coupon_error = false;
      this.coupon_dialog = false;
      if (this.currency !== this.base_currency) {
        this.convert(this.currency, this.base_currency, this.amount_due);
      }
      if (Number(this.discount) === 100) {
        this.send_success_direct();
      }
    },
    test_coupon() {
      this.coupon_error = false;

      if (this.$refs.coupon_form.validate()) {
        this.coupon_progress = true;
        let formdata = new FormData();
        formdata.append("code", this.coupon_code);

        axios
          .post("/api/coupon", formdata)
          .then((res) => {
            this.discount = res.data.discount;
            this.coupon_success = true;
            this.coupon_progress = false;
          })
          .catch((error) => {
            this.coupon_progress = false;

            if (error.response.status === 302) {
              this.coupon_error_message = error.response.data;
              this.coupon_error = true;
            }
          });
      }
    },
    finish_sub() {
      this.$router.push({
        path: "/",
      });
      location.reload();
    },

    send_success(data) {
      this.momoCheckout = false;
      this.progress = false;
      this.complete = true;
      this.selected = false;
    },

    send_success_direct() {
      this.momoCheckout = false;
      this.progress = true;
      let formData = new FormData();
      formData.append("startdate", moment().format("Y-M-D"));
      formData.append(
        "enddate",
        moment().add(this.billing_cycle, "months").format("Y-M-D")
      );
      formData.append("amount", this.amount_due);
      formData.append("plan", this.plan);
      formData.append("coupon_applied", this.coupon_applied);
      formData.append("coupon_code", this.coupon_code);
      formData.append("billing_cycle", this.billing_cycle);
      axios
        .post("/api/appsubcription", formData)
        .then((res) => {
          this.progress = false;
          this.complete = true;
          this.selected = false;
          // this.finish_sub();
        })
        .catch((error) => {
          this.progress = false;
          this.error_msg =
            "Something went wrong, could not save your subscription, please try again";
          this.error_message = true;
        });
    },

    close() {},
    convert(from, to, amount) {
      this.loading = true;
      axios
        .get("/api/convert/" + from + "/" + to + "/" + amount)
        .then((res) => {
          this.send_amount = res.data.to.amount;
          this.loading = false;
        })
        .catch((error) => {});
    },
    convertAndPay(from, to, amount) {
      this.loading_amount = true;
      axios
        .get("/api/convert/" + from + "/" + to + "/" + amount)
        .then((res) => {
          let amount_due = res.data.to.amount;
          this.makePayment(
            amount_due,
            this.plan,
            this.billing_cycle,
            this.coupon_applied,
            this.coupon_code
          );
          this.loading_amount = false;
        })
        .catch((error) => {});
    },
    setDiscount() {
      if (this.promotions.length > 0) {
        let promo = this.promotions.find((item) => {
          return item.months === this.billing_cycle;
        });
        if (promo) {
          this.discount = promo.discount;
          this.redeem_discount();
        } else {
          this.redeem_discount();
          this.discount = 0;
        }
      }
    },
    compute_amount() {
      this.setDiscount();
      this.amount_due = Number(this.amount) * this.billing_cycle;

      this.full_amount = this.amount_due;

      this.send_amount = Number(this.finalAmount);

      if (this.coupon_applied) {
        this.redeem_discount();
      }

      if (this.currency != this.base_currency) {
        this.convert(this.currency, this.base_currency, this.amount_due);
      }
    },
    getCurrentEmployeeCount() {
      axios.get("/api/employees/count").then((res) => {
        this.currentEmployeeCount = res.data.employee_count;
      });
    },
  },
  mounted() {
    if (this.$route.query.cycle) {
      this.billing_cycle = Number(this.$route.query.cycle);
    }


    if (this.$store.state.user.user_infor.current_business.subscription.name == 'basic' || this.$store.state.user.user_infor.current_business.subscription.name == 'trial'|| this.$store.state.user.user_infor.current_business.subscription.name == 'none') {
      this.get_plans();
      this.getPromotions();
      this.subscribed = false;
      this.getCurrentEmployeeCount();

    } else {
      this.subscribed = true;
    }
  }


};
</script>

<style scoped>
.card-hover:hover {
  border: 2px solid #2197f3;
  box-shadow: 0 0 4px;
}

.card-hover {
  transition: 0.3s ease-in-out;
}

.active {
  border: 2px solid #2197f3;
}
</style>