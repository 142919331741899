<template>
	<v-row>
		<v-col cols="4" class="mx-auto text-center">
			<center>
				<v-img width="100" src="/img/icons/fireworks.svg"></v-img>
			</center>
			<h2>{{ title }}</h2>
			<small class="text-muted">{{ description }}</small>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "No Transactions Yet",
		},
		description: {
			type: String,
			default:
				"There are no transactions yet, click on the New + button to add new records",
		},
	},
	name: "norecordsComponent",
};
</script>

<style scoped></style>
