<template>
<v-row dense>
  <v-col
      cols="12"
      sm="4"
      v-for="item in items"
      :key="item.id"
  >
      <v-list-item
          :to="'/invoices/'+item.id"
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/invoices/'+item.id)"
      >
        <v-list-item-content>
          <v-list-item-title>{{item.invoice_number}}</v-list-item-title>
          <v-list-item-subtitle>Amount Due:{{item.fx_currency}}{{item.amount_due | toMoney}}</v-list-item-subtitle>
          <v-list-item-subtitle>Amount Paid:{{item.fx_currency}}{{item.amount_paid | toMoney}}</v-list-item-subtitle>
          <v-list-item-subtitle>Billed To:{{item.customer.business_name}}</v-list-item-subtitle>
          <v-list-item-subtitle>Issue Date:{{item.start_date | humanDate}}</v-list-item-subtitle>
          <v-list-item-subtitle>Due Date:{{item.end_date | humanDate}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

  </v-col>
  <v-col cols="12" sm="4">
    <v-card outlined tile>
      <v-card-text>
        <v-btn
            large
            text
            rounded
            color="blue"
            to="/invoices"
            block
            @click="$emit('close'); $router.push('/invoices')"
        >Go to invoices <v-icon>mdi-arrow-right</v-icon></v-btn>

      </v-card-text>
    </v-card>
  </v-col>
</v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchInvoices"
}
</script>

<style scoped>

</style>