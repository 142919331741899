import homeComponent from "./components/homeComponent";
import VueRouter from "vue-router";
import onboard from "./components/onboard";
import dashboardComponent from "./components/dashboardComponent";
import signinComponent from "./components/patials/signinComponent";
import incomeMenu from "./components/menus/IncomeMenu";
import ExpenseMenu from "./components/menus/ExpenseMenu";
import AccountingMenu from "./components/menus/AccountingMenu";
import registerComponent from "./components/patials/registerComponent";
import initComponent from "./components/initComponent";
import Vue from "vue";
import {store} from "./store";
import blackFridayComponent from "./components/promo/blackFridayComponent";
import $eventHub from "./components/eventHub";
import {getAccessToken} from "./utils";
import invoicepreviewComponent from "./components/invoices/invoicepreviewComponent";


function getCountryCode() {
	let CountryRoute = "";
	if (store.state.user) {

		let countryCode = localStorage.getItem("business_country");


		if (!countryCode) {

			countryCode = store.state.user.user_infor.current_business.country.code;
		}

		CountryRoute = countryCode.toUpperCase() === "GH" ? "" : countryCode.toUpperCase() + "/";

	}

	return CountryRoute;
}

Vue.use(VueRouter);
const routes = [
	{
		path: "/income",
		component: incomeMenu,
		name: "InomeMenu",
	},
	{
		path: "/expense",
		component: ExpenseMenu,
		name: "ExpenseMenu",
	},
	{
		path: "/accounting",
		component: AccountingMenu,
		name: "AccoutningMenu",
	},
	{
		path: "/deposits",
		component: () => import("../src/components/deposit/DepositComponent.vue"),
		name: "Deposits",
	},
	{
		path: "/deposits/:id",
		component: () => import("../src/components/deposit/DepositComponent.vue"),
		name: "depositPreview",
	},
	{
		path: "/deposits/transactions",
		component: () => import("../src/components/deposit/DepositComponent.vue"),
		name: "depositTransactions",
	},
	{
		path: "/deposits/depositors",
		component: () => import("../src/components/deposit/DepositComponent.vue"),
		name: "depositors",
	},
	{
		path: "/deposits/depositors/:id",
		component: () => import("../src/components/deposit/DepositComponent.vue"),
		name: "depositorDetails",
	},
	{
		path: "/onboard",
		component: onboard,
		name: "Onboard",
	},
	{
		path: "/",
		component: dashboardComponent,
		name: "Home",
	},
	{
		path: "/promo/:code",
		component: blackFridayComponent,
		name: "promo",
	},
	{path: "/init", component: initComponent},
	{path: "/audit", component: () => import("./components/audit/index")},
	{
		path: "/bank-reconciliation",
		component: () => import("./components/reconciliation"),
		children: [
			{
				path: "",
				component: () => import("./components/reconciliation/home"),},
      {

				path: "fix/:uuid",
				component: () => import("./components/reconciliation/viewTransactions"),
			},
			{
				path: ":account_id",
				component: () => import("./components/reconciliation/new"),
			},
		],
	},
	{
		path: "/files",
		component: () => import("./components/files"),
	},
	{
		path: "/help",
		component: () => import("./components/patials/helpPage"),
	},
	{
		path: "/loans",
		component: () => import("./components/loans/loansComponent"),
	},
	{
		path: "/loans/:uuid",
		component: () => import("./components/loans/loansComponent"),
	},
	{
		path: "/analytics",
		component: () => import("./components/analytics/index"),
		children: [
			{
				path: "",
				component: () => import("./components/analytics/dashboard"),
			},
			{
				path: "customers",
				component: () => import("./components/analytics/allcustomers"),
			},
			{
				path: "suppliers",
				component: () => import("./components/analytics/allsupliers"),
			},
			{
				path: "products",
				component: () => import("./components/analytics/allproducts"),
			},
			{
				path: "product-categories",
				component: () => import("./components/analytics/AllItemCategories"),
			},
			{
				path: "agedreceivables",
				component: () => import("./components/analytics/AllAgedReceivables"),
			},
			{
				path: "agedpayables",
				component: () => import("./components/analytics/AllAgedPayables"),
			},
		],
	},

	{
		path: "/dashboard",
		component: () => import("./components/dashboardComponent"),
	},

	{
		path: "/sync",
		component: () => import("./components/sync/index"),
		children: [
			{
				path: "",
				component: () => import("./components/sync/menuComponent"),
			},

			{
				path: "income",
				component: () => import("./components/sync/incomeComponent"),
			},
			{
				path: "income/:id",
				component: () => import("./components/sync/incomeComponent"),
			},

			{
				path: "expense",
				component: () => import("./components/sync/expenseComponent"),
			},
			{
				path: "expense/:id",
				component: () => import("./components/sync/expenseComponent"),
			},
		],
	},

	{
		path: "/i18n",
		component: () => import("./components/i18n"),
	},
	{
		path: "/offerbox",
		component: () => import("./components/finance_services/offerboxComponent"),
	},
	{
		path: "/offerbox/policies",
    component: () => import("./components/finance_services/HomeComponent"),
    children: [
      {
        path: "",
        component: () =>
          import("./components/finance_services/dashboardComponent"),
      },
      {
        path: "dash",
        component: () =>
          import("./components/finance_services/dashboardComponent"),
      },
      {
        path: "orders",
        component: () => import("./components/finance_services/orders"),
      },
      {
        path: "claims",
        component: () => import("./components/finance_services/Claims"),
      },
      {
        path: "claims/:id",
        component: () => import("./components/finance_services/claim"),
      },

      {
        path: "policies",
        component: () => import("./components/finance_services/policies"),
      },
      {
        path: "orders/:id",
        component: () => import("./components/finance_services/order"),
      },
    ],
  },
  {
    path: "/tags",
		component: () => import("./components/tags/indexComponent"),
	},
	{
		path: "/tag/:tag",
		component: () => import("./components/tags/TagDashboardComponent"),
	},
	{
		path: "/offerbox/:id",
		component: () => import("./components/finance_services/offerboxDetail"),
		name: "offer-box-detail",},
  {
    path: "/insurance/:id",
    component: () =>
      import("./components/finance_services/insuranceDetails.vue"),
    name: "insurance-details",
	},
	{
		path: "/login",
		redirect: "/auth/login",
	},
	{
		path: "/register",
		redirect: "/auth/signup",
	},
	{
		path: "/signup",
		redirect: "/auth/signup",
	},

	{
		path: "/transaction/:id/:startdate/:enddate",
		component: () => import("./components/patials/transactionsComponent"),
	},
	{
		path: "/customers",
		component: () => import("./components/clientsComponent"),
	},
	{
		path: "/customers/:id",
		component: () => import("./components/agents/customerDetail"),
	},
	{
		path: "/settings",
		component: () => import("./components/businesssettingsComponent"),
	},
	{path: "/home", component: homeComponent},
	{
		path: "/suppliers",
		component: () => import("./components/supplierComponent"),
	},
	{
		path: "/suppliers/:id",
		component: () => import("./components/agents/supplierDetail"),
	},
	{
		path: "/applications/:offerId",
		component: () => import("./components/applicationForm"),
		name: "applications",
	},

	{
		path: "/transaction/:id/:startdate/:enddate",
		component: () => import("./components/patials/transactionsComponent"),
	},
	{
		path: "/customers",
		component: () => import("./components/clientsComponent"),
	},
	{
		path: "/customers/:id",
		component: () => import("./components/agents/customerDetail"),
	},
	{
		path: "/settings",
		component: () => import("./components/businesssettingsComponent"),
	},
	{path: "/home", component: homeComponent},
	{
		path: "/suppliers",
		component: () => import("./components/supplierComponent"),
	},
	{
		path: "/suppliers/:id",
		component: () => import("./components/agents/supplierDetail"),
	},
	{
		path: "/applications/:offerId",
		component: () => import("./components/applicationForm"),
		name: "applications",
	},
	{
		path: "/inventory",
		component: () => import("./components/product/index"),
		children: [
			{
				path: "",
				component: () => import("./components/inventoryComponent"),
			},
			{
				path: "adjust",
				component: () => import("./components/product/bulkAdjustment"),
			},
			{
				path: "adjust/:id",
				component: () => import("./components/product/bulkAdjustment"),
			},
			{
				path: "newadjustment",
				component: () => import("./components/product/NewBulkAdjustment"),
			},
			{
				path: ":id",
				component: () => import("./components/product/productDetail"),
				props: true,
			},
		],
	},
	{
		path: "/quotes",
		component: () => import("./components/quotes/quotesViewComponent"),
		children: [
			{
				path: "",
				component: () => import("./components/quotes/quoteComponent"),
			},
			{
				path: "new",
				component: () => import("./components/quotes/newQuoteComponent"),
			},
			{
				path: ":id",
				component: () => import("./components/quotes/quotepreviewComponent"),
			},
			{
				path: "edit/:id",
				component: () => import("./components/quotes/editQuoteComponent"),
			},
		],
	},

	{
		path: "/newquote",
		component: () => import("./components/quotes/newQuoteComponent"),
	},
	{
		path: "/editquote/:id",
		component: () => import("./components/quotes/editQuoteComponent"),
	},

  {
    path: "/invoices",
    component: () => import("./components/invoices/invoicesComponent"),
    children: [
      {
        path: "",
        component: () => import("./components/invoices/invoicesviewComponent"),
      },
      {
        path: "new",
        component: () => import("./components/invoices/newInvoiceComponent"),
      },
      {
        path: "newquote",
        component: () => import("./components/quotes/newQuoteComponent"),
      },
      {
        path: "quote/:id",
        component: () => import("./components/quotes/quotepreviewComponent"),
      },
      {
        path: "edit/:id",
        component: () => import("./components/invoices/editInvoiceComponent"),
      },
      {
        path: "editquote/:id",
        component: () => import("./components/quotes/editQuoteComponent"),
      },
      {
        path: "convertquote/:id",
        component: () =>
          import("./components/invoices/convertQuoteToInvoice.vue"),
      },
      {
        path: ":id",
        component: invoicepreviewComponent,
      },
    ],
  },

	{
		path: "/paidexpenses",
		component: () => import("./components/expenses/index"),
	},

	{
		path: "/paidexpenses/:id",
		component: () => import("./components/expenses/index"),
	},

	{
		path: "/expenses",
		component: () => import("./components/expenseviewComponent"),
		children: [
			{
				path: "",
				component: () => import("./components/expensesComponent"),
			},
			{
				path: "newbill",
				component: () => import("./components/patials/newbillComponent"),
			},
			{
				path: "paid",
				component: () => import("./components/patials/otherexpensesComponent"),
			},
			{
				path: "editbill/:id",
				component: () => import("./components/patials/editbillComponent"),
			},
			{
				path: "bills/:id",
				component: () => import("./components/patials/billpreviewComponent"),
			},
		],
	},
	{
		path: "/sales",
		component: () => import("./components/salesviewComponent"),
		children: [
			{
				path: "",
				component: () => import("./components/salesComponent"),
			},
			{
				path: "new",
				component: () => import("./components/patials/newsaleComponent"),
			},
			{
				path: ":id",
				component: () => import("./components/patials/salespreviewComponent"),
			},
			{
				path: "edit/:id",
				component: () => import("./components/patials/editsaleComponent"),
			},
		],
	},
	{
		path: "/wallet",
		redirect: "/wallet/dashboard",
	},
	{
		path: "/wallet",
		component: () =>
			import("./components/wallet/" + getCountryCode() + "index"),
		children: [
			{
				path: "",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "dashboard"),
			},
			{
				path: "dashboard",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "dashboard"),
			},
			{
				path: "links",
				component: () => import("./components/wallet/links"),
			},
			{
				path: "links/:uuid",
				component: () => import("./components/wallet/links"),
			},
			{
				path: "cards",
				component: () => import("./components/wallet/cardsView"),
				children: [
					{
						path: "",
						component: () => import("./components/wallet/cards"),
					},
					{
						path: ":uuid",
						component: () =>
							import("./components/wallet/" + getCountryCode() + "cardview"),
					},
				],
			},
			{
				path: "pay",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "pay"),
			},
			{
				path: "payments",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "payments"),
			},
			{
				path: "bank-transfers",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "BankTransfers"),
			},

			{
				path: "topup",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "topup"),
			},
			{
				path: "transactions",
				component: () => import("./components/wallet/transactions"),
			},
			{
				path: "transaction/:uuid",
				component: () => import("./components/wallet/transaction"),
			},
			{
				path: "withdraw",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "withdraw"),
			},
			{
				path: "settings",
				component: () => import("./components/wallet/settings"),
				children: [
					{
						path: "",
						component: () => import("./components/wallet/settingsMenu"),
					},
					{
						path: "profile",
						component: () =>
							import("./components/wallet/" + getCountryCode() + "profile"),
					},
					{
						path: "accounts",
						component: () => import("./components/wallet/accounts"),
					},
					{
						path: "security",
						component: () => import("./components/wallet/security"),
					},
				],
			},
			{
				path: "services",
				component: () =>
					import("./components/wallet/" + getCountryCode() + "services"),
				children: [
					{
						path: "",
						component: () =>
							import(
							"./components/wallet/" + getCountryCode() + "serivceCategories"
								),
					},

					{
						path: ":category",
						component: () =>
							import("./components/wallet/" + getCountryCode() + "service"),
					},
				],
			},
		],
	},
	{
		path: "/accounts",
		component: () => import("./components/accountsComponent"),
	},
	{
		path: "/pos",
		component: () => import("./components/patials/posComponent"),
	},
	{
		path: "/journal/:id",
		component: () => import("./components/journalComponent"),
	},
	{
		path: "/journal",
		component: () => import("./components/journalComponent"),
	},
	{
		path: "/expense/:id",
		component: () => import("./components/expensepreviewComponent"),
	},
	{
		path: "/incomes",
		component: () => import("./components/otherincomesComponent"),
	},
	{
		path: "/ledger",
		component: () => import("./components/generalledgerComponent"),
	},
	{
		path: "/balancesheet",
		component: () => import("./components/balancesheetComponent"),
	},
	{
		path: "/incomestatement",
		component: () => import("./components/incomestatementComponent"),
	},
	{
		path: "/cashflow",
		component: () => import("./components/NewCashflowComponent"),
	},
	{
		path: "/taxpayment",
		component: () => import("./components/tax_payments/index"),
		children: [
			{
				path: "",
				component: () => import("./components/tax_payments/home"),
			},
			{
				path: "sales",
				component: () =>
					import("./components/tax_payments/salestaxpaymentComponent"),
			},
			{
				path: "income",
				component: () => import("./components/tax_payments/incometaxPayments"),
			},
			{
				path: "income/:uuid",
				component: () => import("./components/tax_payments/incometaxPayments"),
			},
			{
				path: "withholding",
				component: () =>
					import("./components/tax_payments/withholdingTaxPayments"),
			},
			{
				path: "withholding/:uuid",
				component: () =>
					import("./components/tax_payments/withholdingTaxPayments"),
			},
		],
	},
	{
		path: "/openingbalance",
		component: () => import("./components/startingbalanceComponent"),
	},

	{
		path: "/openingbalance/:id",
		component: () => import("./components/startingbalanceComponent"),
	},
	{
		path: "/taxreports",
		component: () => import("./components/taxreportsComponent"),
	},
	{
		path: "/withholdingtaxreports",
		component: () => import("./components/withholdingTaxReport"),
	},
	{
		path: "/trialbalance",
		component: () => import("./components/trialbalanceComponent"),
	},
	{
		path: "/usersettings",
		component: () => import("./components/settingsComponent"),
	},
	{path: "/plans", component: () => import("./components/plansComponent")},
	{
		path: "/banktransfer",
		component: () => import("./components/banktransferComponent"),
	},
	{
		path: "/banktransfer/:id",
		component: () => import("./components/banktransferComponent"),
	},
	{
		path: "/subscriptions",
		component: () => import("./components/subscriptionsComponent"),
	},
	{
		path: "/creditrequest",
		component: () => import("./components/creditComponent"),
	},
	{
		path: "/lostanggains",
		component: () => import("./components/patials/LostAndGainsReportComponent"),
	},
	{
		path: "/reports",
		component: () => import("./components/patials/reportPreview"),
	},
	{
		path: "/payroll",
		component: () => import("./components/payroll/payrollDashboard"),
		children: [
			{
				path: "dashboard",
				component: () => import("./components/payroll/patials/indexComponent"),
			},
			{
				path: "",
				component: () => import("./components/payroll/patials/indexComponent"),
			},
			{
				path: "p9form",
				component: () =>
					import("./components/payroll/patials/reports/P9FormComponent"),
			},
			{
				path: "p9form/:employee_id",
				component: () =>
					import("./components/payroll/patials/reports/P9FormComponent"),
			},
			{
				path: "departments",
				component: () => import("./components/payroll/patials/depComponent"),
			},
			{
				path: "employees",
				component: () =>
					import("./components/payroll/patials/employeesComponent"),
			},
			{
				path: "employee/:id",
				component: () =>
					import("./components/payroll/patials/employeeComponent"),
			},
			{
				path: "settings",
				component: () =>
					import("./components/payroll/patials/payrollsettingsComponent"),
			},


			{
				path: "payruns",
				component: () =>
					import("./components/payroll/patials/payrunsComponent"),
			},
			{
				path: "payment/:id",
				component: () =>
					import("./components/payroll/patials/salarypaymentComponent"),
			},
			{
				path: "taxpayment/:id",
				component: () =>
					import("./components/payroll/patials/taxPaymentComponent"),
			},

			{
				path: "/invoices",
				component: () => import("./components/invoices/invoicesComponent"),
				children: [
					{
						path: "",
						component: () => import("./components/invoices/invoicesviewComponent"),
					},
					{
						path: "new",
						component: () => import("./components/invoices/newInvoiceComponent"),
					},
					{
						path: "newquote",
						component: () => import("./components/quotes/newQuoteComponent"),
					},
					{
						path: "quote/:id",
						component: () => import("./components/quotes/quotepreviewComponent"),
					},
					{
						path: "edit/:id",
						component: () => import("./components/invoices/editInvoiceComponent"),
					},
					{
						path: "editquote/:id",
						component: () => import("./components/quotes/editQuoteComponent"),
					},
					{
						path: "convertquote/:id",
						component: () =>
							import("./components/invoices/convertQuoteToInvoice.vue"),
					},
					{
						path: ":id",
						component: invoicepreviewComponent

					},
				],
			},

			{
				path: "tier1Payment/:id",
				component: () =>
					import("./components/payroll/patials/tier1PaymentComponent"),
			},

			{
				path: "tier3payment/:id",
				component: () => import("./components/payroll/patials/tier3Payment"),
			},

			{
				path: "tier2Payment/:id",
				component: () =>
					import("./components/payroll/patials/tier2PaymentComponent"),
			},
			{
				path: "payrun/:id",
				component: () => import("./components/payroll/patials/payrunComponent"),
			},
			{
				path: "payslip/:id",
				component: () =>
					import("./components/payroll/patials/payslipComponent"),
			},
			{
				path: "payslips/:id",
				component: () =>
					import("./components/payroll/patials/payslipsComponent"),
			},

			{
				path: "tier3/:id",
				component: () => import("./components/payroll/patials/reports/Tier3"),
			},

			{
				path: "ssnittier1/:id",
				component: () => import("./components/payroll/patials/tier1Component"),
			},

			{
				path: "nhif/:id",
				component: () => import("./components/payroll/patials/nhifcomponent"),
			},

			{
				path: "ssnittier2/:id",
				component: () => import("./components/payroll/patials/tier2Component"),
			},
			{
				path: "taxreport/:id",
				component: () =>
					import("./components/payroll/patials/taxreportComponent"),
			},
			{
				path: "summarysheet/:id",
				component: () =>
					import("./components/payroll/patials/summarysheetComponent"),
			},
			{
				path: "salariescheckout/:id",
				component: () =>
					import("./components/payroll/patials/salariescheckoutComponent"),
			},
		],
	},
	{
		path: "/subscription/:id",
		component: () => import("./components/subscriptionComponent"),
		props: true,
	},
	{
		path: "/auth",
		component: () => import("./components/authComponent"),
		children: [
			{
				path: "",
				component: () => import("./components/patials/signinComponent"),
			},
			{
				path: "login",
				component: signinComponent,
				name: "Login",
			},
			{
				path: "signup",
				component: registerComponent,
				name: "Signup",
			},
		],
	},
	{
		path: "/affiliate",
		component: () => import("./components/affiliate/indexComponent"),
	},
	{
		path: "/calculator",
		component: () => import("./components/calculator"),
		children: [
			{
				path: "",
				component: () => import("./components/calculator/index"),
			},
		],
	},

	{path: "*", component: () => import("./components/NotFoundComponent")},
];

const router = new VueRouter({
	routes,
	mode: "history",
});

router.beforeEach((to, from, next) => {
  if (
    typeof to.matched[to.matched.length - 1]?.components.default === "function"
  ) {
    $eventHub.$emit("asyncComponentLoaded");
    $eventHub.$emit("asyncComponentLoading", to); // Start progress bar
  }
  next();
});

router.beforeResolve((to, from, next) => {
	$eventHub.$emit("asyncComponentLoaded"); // Stop progress bar
	next();
});

router.beforeEach((to, from, next) => {
	if (
		to.path !== "/auth/login" &&
		to.path !== "/auth/signup" &&
		to.path !== "/register" &&
		to.path !== "/init" &&
		getAccessToken() &&
		getAccessToken().length <= 4
	) {

		next({path: "/auth/login"});
	} else if (
		to.path !== "/onboard" &&
		to.path !== "/auth/login" &&
		to.path !== "/auth/signup" &&
		to.path !== "/register" &&
		store.state.init_state > 60 &&
		store.state.user &&
		!store.state.user.user_infor.has_business
	) {
		next({name: "Onboard"});
	} else {
		next();
	}
});

export default router;
