<template>
  <v-row dense>
    <v-col cols="12" sm="3" v-for="item in items" :key="item.id">
      <v-card style="text-decoration: none" outlined class="card-effect">
        <v-img :src="item.photo" contain height="150px"></v-img>
        <v-card-title>
          {{ item.selling_price | toMoney | currency_symbol
          }}<v-spacer></v-spacer>
          <v-chip small color="blue" class="font-weight-black" outlined
            >{{ item.code }}<v-icon small>mdi-tag-outline</v-icon></v-chip
          >
        </v-card-title>
        <v-card-text>
          Name:<strong>{{ item.name }}</strong
          ><br />
          Unit Price:<strong>{{
            item.rawUnit_price | toMoney | currency_symbol
          }}</strong
          ><br />
          Description:<strong>{{ item.description }}</strong
          ><br />
        </v-card-text>

        <v-card-text>
          <v-chip-group column @click="$emit('close')">
            <v-chip
              color="warning lighten-5 warning--text"
              v-if="item.buying_item === 1"
              :to="'/expenses/newbill?item_id=' + item.id"
              @click="
                $emit('close');
                $router.push('/expenses/newbill?item_id=' + item.id);
              "
              >Buy <v-icon small>mdi-cart-plus</v-icon></v-chip
            >
            <v-chip
              v-if="item.retail_item == 1"
              color="green lighten-5 green--text"
              :to="'/invoices/new?item_id=' + item.id"
              @click="
                $emit('close');
                $router.push('/invoices/new?item_id=' + item.id);
              "
              >Sell<v-icon small>mdi-cart-minus</v-icon></v-chip
            >
            <v-chip
              :to="'/inventory/' + item.id"
              style="text-decoration: none"
              color="blue lighten-5 blue--text"
              draggable
              @click="
                $emit('close');
                $router.push('/inventory/' + item.id);
              "
              >{{ $t("main.preview") }}<v-icon small>mdi-eye</v-icon></v-chip
            >
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="3">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
            text
            rounded
            color="blue"
            to="/inventory"
            block
            @click="
              $emit('close');
              $router.push('/inventory/');
            "
            >Go to product & service <v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["items"],
  name: "SearchInventory",
  computed: {
    baseURL() {
      return this.$store.state.baseURL;
    },
  },
};
</script>

<style scoped>
.card-effect:hover {
  background-color: #9e9e9e2a;
}
.card-effect {
  transition: 0.3s ease-in-out;
}
</style>