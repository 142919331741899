<template>
  <v-dialog transition="dialog-bottom-transition"
            width="400px"
            v-model="$store.state.showSuccess">
  <v-card flat color="green lighten-5">
    <lottie-animation
        ref="finishAnim"
        :animationData="require('@/assets/animations/success.json')"
        :autoPlay="true"
        :loop="false"
        style="height: 200px"
    />
    <center>
      <h1 class="font-weight-black green--text pa-2">{{title}}</h1>
      <h3 class="font-weight-light pa-4">{{description}}</h3>
    </center>
    <v-card-actions>
      <v-btn @click="$store.state.showSuccess=false; $emit('done')" block color="green" text rounded depressed>{{$t("main.ok")}}</v-btn>
    </v-card-actions>
  </v-card>

  </v-dialog>

</template>

<script>
import LottieAnimation from 'lottie-web-vue'

export default {
  name: "SuccessAnimation",
  props:{

    title:{
      default:"Great!"
    },
    description:{
      default:"Transaction successful!"
    }
  },
  components: {
    LottieAnimation
  }

}
</script>

<style scoped>

</style>