<template>
<span>
  <v-row dense>
    <v-col cols="12" v-if="customers" >
      <v-card flat>


        <p class="display-2 green--text text-center">

        {{total | short_number | currency_symbol}}

        </p>
        <v-card-text style="height: 800px; overflow: auto">
 <v-list v-if="customers.length >0">
            <template v-for="(debtor, index) in customers">

                <v-list-item
                    :key="debtor.id"
                    link
                >
                    <v-list-item-avatar>

                      <avatar-component :label="debtor.business_name"></avatar-component>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn text color="green" :to="'/customers/'+debtor.id" target="_blank" >{{debtor.business_name}}<v-icon x-small>mdi-arrow-top-right</v-icon></v-btn>

                        </v-list-item-title>
                    </v-list-item-content>
                    <v-chip color="green lighten-5" class="text-success">{{debtor.balance | currency_symbol}}</v-chip>
                </v-list-item>
            </template>

            <template>
                <v-list-item>
                    <v-btn rounded block text color="blue" to="/customers"> View All</v-btn>
                </v-list-item>
            </template>
        </v-list>
        <center class="mt-5" v-else>
            <v-icon x-large class="text-muted mt-5">mdi-account-off-outline</v-icon>
            <h4>You have no customer at the moment</h4>
            <v-btn small rounded text color="blue" to="/customers" @click="$store.state.new_customer=true" >Create Customer</v-btn>
        </center>

        </v-card-text>
      </v-card>
    </v-col>


    <v-col cols="12" v-if="suppliers">
      <v-card flat>

        <p class="display-2 red--text text-center">

        {{total | short_number | currency_symbol}}

        </p>
        <v-card-text style="height: 800px; overflow: auto">
 <v-list v-if="suppliers.length >0" >
            <template v-for="(creditor, index) in suppliers">


                <v-list-item
                    :key="creditor.id"
                    color="red lighten-3"
                    dense
                    link
                >
                    <v-list-item-avatar>

                      <avatar-component :label="creditor.supplier_name"></avatar-component>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn text color="red" :to="'/suppliers/'+creditor.id" target="_blank" >{{creditor.supplier_name}}<v-icon x-small>mdi-arrow-top-right</v-icon></v-btn>

                        </v-list-item-title>
                    </v-list-item-content>
                    <v-chip color="red lighten-5" class="red--text">{{creditor.balance | currency_symbol}}</v-chip>
                </v-list-item>
            </template>

            <template>
                <v-list-item>
                    <v-btn rounded block text color="blue" to="/suppliers"> View All</v-btn>
                </v-list-item>
            </template>
        </v-list>
        <center class="mt-5" v-else>
            <v-icon x-large class="text-muted mt-5">mdi-account-off-outline</v-icon>
            <h4>You have no customer at the moment</h4>
            <v-btn small rounded text color="blue" to="/suppliers" @click="$store.state.new_customer=true" >Create Customer</v-btn>
        </center>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>
</span>
</template>

<script>
import AvatarComponent from "../agents/avatarComponent";
export default {
  components: {AvatarComponent},
  props:['customers','suppliers','total'],
  name: "creditDebitComponent",

}
</script>

<style scoped>

</style>