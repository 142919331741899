<template>
<v-row dense>
  <v-skeleton-loader type="list-item@10" v-if="progress"></v-skeleton-loader>
  <v-col cols="12" v-else-if="deposits" >
    <v-card flat>


      <p class="display-2 blue--text text-center">
        {{deposits.total | short_number | currency_symbol}}
      </p>

      <v-card-text style="height: 800px; overflow: auto">
        <v-list v-if="deposits.list.length >0">
          <template v-for="debtor in deposits.list">

            <v-list-item
                :key="debtor.id"
                link
            >
              <v-list-item-avatar>

                <avatar-component :label="debtor.business_name"></avatar-component>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <v-btn text color="blue" :to="'/customers/'+debtor.id" target="_blank" >{{debtor.business_name}}<v-icon x-small>mdi-arrow-top-right</v-icon></v-btn>

                </v-list-item-title>
              </v-list-item-content>
              <v-chip color="blue lighten-5 blue--text">{{debtor.deposit_amount | currency_symbol}}</v-chip>
            </v-list-item>
          </template>

          <template>
            <v-list-item>
              <v-btn rounded block text color="blue" to="/customers"> View All</v-btn>
            </v-list-item>
          </template>
        </v-list>
        <center class="mt-5" v-else>
          <v-icon x-large class="text-muted mt-5">mdi-account-off-outline</v-icon>
          <h4>You have not recorded any deposits at the moment</h4>
          <v-btn small rounded text color="blue" to="/customers" >Go to customer page</v-btn>
        </center>

      </v-card-text>
    </v-card>
  </v-col>

</v-row>
</template>

<script>
import avatarComponent from "../agents/avatarComponent";
export default {
  name: "DahsboardDepositsComponent",
  components:{avatarComponent},
  data(){
    return{
      deposits:null,
      progress:false
    }
  },
  methods:{
    getDeposits(){
      this.progress=true;
      axios.get("/api/v2/dashboard/deposits")
        .then(res=>{
          this.progress=false;
          this.deposits=res.data;
        })

    }
  },
  mounted() {
    this.getDeposits();
  }
}
</script>

<style scoped>

</style>