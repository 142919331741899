<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          :to="'/quotes/'+item.id"
          class="border border-warning"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/quotes/'+item.id)"
      >
        <v-list-item-content>
          <v-list-item-title>{{item.quote_number}}</v-list-item-title>
          <v-list-item-subtitle>Amount Due:{{item.fx_currency}}{{item.amount_due | toMoney}}</v-list-item-subtitle>
          <v-list-item-subtitle>Billed To:{{item.customer.business_name}}</v-list-item-subtitle>
          <v-list-item-subtitle>Issue Date:{{item.created_at | humanDate}}</v-list-item-subtitle>
          <v-list-item-subtitle>Valid Till:{{item.valid_until | humanDate}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="warning"
              to="/invoices"
              block
              @click="$emit('close'); $router.push('/quotes')"
          >Go to Quotes <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchQuotes"
}
</script>

<style scoped>

</style>