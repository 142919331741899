<template>
  <v-card>
    <v-card-title class="font-weight-light"
      >Discount invoice - #{{ invoice.invoice_number }}
      <v-spacer></v-spacer>
      <v-btn
        :disabled="progress"
        color="red lighten-5 red--text"
        rounded
        depressed
        small
        @click="$emit('closed')"
        >Close
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-stepper v-model="step" style="box-shadow: none" class="pa-0">
        <v-stepper-header style="box-shadow: none" class="p-0">
          <v-stepper-step :complete="step == 1" step="1"> </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step == 2" step="2" :editable="step == 3">
            Your request
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="step == 3" step="3">
            Other info.
          </v-stepper-step>

          <v-divider></v-divider>
        </v-stepper-header>

        <v-stepper-items class="pa-0">
          <v-stepper-content step="1" class="pa-0">
            <center>
              <v-img src="/img/payment.svg"></v-img>

              <h1 class="font-weight-black mt-4">Want to get paid upfront?</h1>
              <h2 class="font-weight-light">
                Factor your invoice through
                <strong
                  class="blue--text font-weight-black text-decoration-underline"
                  >Built</strong
                >
              </h2>
            </center>

            <span v-if="isOverdue || duration === 1">
              <v-alert
                v-if="isOverdue"
                type="warning"
                color="warning lighten-5 warning--text"
                class="text-center"
              >
                <h4 class="font-weight-black">
                  Unfortunately this invoice is not eligible for discounting
                </h4>
                <h5 v-if="isOverdue">The invoice is overdue</h5>
                <h5 v-if="duration === 1">
                  Invoices payable within a day are not qualified for
                  discounting
                </h5>
              </v-alert>
            </span>
            <v-btn
              @click="step = 2"
              v-else
              large
              rounded
              depressed
              color="blue darken-4"
              dark
              block
              class="mt-3"
              >Discount invoice #{{ invoice.invoice_number }}
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <center>
              <h3 class="font-weight-black mb-5">
                Invoice amount due:{{ invoice.fx_currency
                }}{{ invoice.amount_due | toMoney }}
              </h3>

              <v-slider
                @change="sliderChanged"
                track-color="blue darken-4"
                min="0"
                max="85.5"
                v-model="percent"
                class="m-5 mb-2"
                thumb-color="blue darken-4"
                thumb-label="always"
                color="blue darken-4"
                rounded
                step="0.5"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ percent }}%
                </template>
              </v-slider>
            </center>

            <v-form ref="requestForm">
              <v-text-field
                outlined
                label="How much do you need?"
                :hint="
                  'Up to 85% of invoice amount due ' +
                  invoice.fx_currency +
                  max_amount
                "
                class="mt-3"
                persistent-hint
                type="number"
                v-model="amount"
                :prefix="invoice.fx_currency"
                autofocus
                :rules="$store.state.requiredRule"
              ></v-text-field>

              <h4 class="mt-3">
                Due date: <strong>{{ invoice.end_date | humanDate }}</strong>
              </h4>
              <h4 class="mb-3">
                Customer Name:
                <strong>{{ invoice.customer.business_name }}</strong>
              </h4>

              <v-text-field
                :rules="$store.state.requiredRule"
                type="tel"
                v-model="customer_phone"
                outlined
                label="Customer Phone Number"
              ></v-text-field>

              <v-text-field
                :rules="$store.state.email_rules"
                type="email"
                v-model="customer_email"
                outlined
                label="Customer Email"
              ></v-text-field>
            </v-form>

            <p v-if="percent > 85" class="red--text">
              Error: Amount must not be more than 85% of invoice amount due
            </p>
            <v-btn
              color="blue darken-4"
              dark
              rounded
              large
              depressed
              @click="moveNext"
              v-if="percent <= 85"
              block
              class="mt-3"
              >Continue<v-icon>mdi-arrow-right</v-icon></v-btn
            >
          </v-stepper-content>

          <v-stepper-content step="3" class="pa-0">
            <success-animation
              @done="done"
              title="Success"
              :description="success_message"
              v-if="$store.state.showSuccess"
            ></success-animation>
            <span>
              <v-btn text rounded @click="step = 2"
                ><v-icon>mdi-arrow-left</v-icon>Back</v-btn
              >
              <h3 class="font-weight-light mb-5">
                Invoice amount due:<strong
                  >{{ invoice.fx_currency
                  }}{{ invoice.amount_due | toMoney }}</strong
                >
              </h3>
              <h3 class="font-weight-light mb-5">
                You requested:<strong
                  >{{ invoice.fx_currency }}{{ amount | toMoney }}({{
                    percent | toMoney
                  }}%)</strong
                >
              </h3>

              <v-textarea
                v-model="note"
                rows="4"
                autofocus
                auto-grow
                label="Other notes"
                outlined
              ></v-textarea>

              <h4 class="font-weight-bold mb-5">
                Attach a purchase order or any other document if any
              </h4>
              <v-file-input
                v-model="attachment"
                readonly
                outlined
                label="Select file"
                prepend-icon="mdi-paperclip"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="blue">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <v-btn
                :loading="progress"
                @click="sendDiscountRequest"
                rounded
                block
                color="blue darken-4"
                large
                depressed
                dark
                >Send request <v-icon>mdi-check-circle</v-icon></v-btn
              >
            </span>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>

    <v-snackbar v-model="error">{{ error_message }}</v-snackbar>
  </v-card>
</template>

<script>
import moment from "moment";
import SuccessAnimation from "../wallet/components/SuccessAnimation";

export default {
  components: { SuccessAnimation },
  props: {
    invoice: {
      type: Object,
      default: null,
    },
  },
  name: "DiscountingComponent",
  data() {
    return {
      error_message: "",
      error: false,
      step: 1,
      amount: 0,
      percent: 0,
      customer_phone: "",
      customer_email: "",
      attachment: null,
      note: "",
      progress: false,
      success_message: "",
    };
  },
  watch: {
    amount() {
      this.percent = (
        (Number(this.amount) / Number(this.invoice_amount_due)) *
        100
      ).toFixed(2);
    },
    invoice() {
      this.customer_email = this.invoice.customer.business_email;
      this.customer_phone = this.invoice.customer.business_phone;
      this.amount = 0;
    },
  },
  computed: {
    max_amount() {
      return ((85 / 100) * Number(this.invoice_amount_due)).toFixed(2);
    },
    invoice_amount_due() {
      return this.invoice.amount_due;
    },
    issueDate() {
      return this.invoice ? this.invoice.start_date : null;
    },
    dueDate() {
      return this.invoice ? this.invoice.end_date : null;
    },

    isOverdue() {
      return moment().isAfter(this.dueDate);
    },
    duration() {
      const to = moment(this.dueDate);
      return to.diff(moment(), "days");
    },
  },
  methods: {
    done() {
      this.step = 1;
      this.$emit("closed");
    },

    sendDiscountRequest() {
      if (this.$refs.requestForm.validate()) {
        this.progress = true;

        const formData = new FormData();

        formData.append("invoice_id", this.invoice.id);
        formData.append("amount", this.amount);
        formData.append("customer_name", this.invoice.customer.business_name);
        formData.append("customer_phone_number", this.customer_phone);
        formData.append("customer_email", this.customer_email);
        formData.append("note", this.note);
        formData.append("attachment", this.attachment);

        axios
          .post("/api/invoice/discount", formData)
          .then((res) => {
            this.progress = false;
            this.success_message =
              "Your request to discount invoice #" +
              this.invoice.invoice_number +
              " with " +
              this.invoice.fx_currency +
              this.amount +
              " was successful";
            this.$store.state.showSuccess = true;
          })
          .catch((error) => {
            this.error_message =
              error.response.status === 302
                ? error.response.data
                : "Something went wrong, could not send request";
            this.error = true;
            this.progress = false;
          });
      }
    },
    moveNext() {
      if (this.$refs.requestForm.validate()) {
        this.step = 3;
      }
    },
    sliderChanged() {
      this.amount = (
        (Number(this.percent) / 100) *
        Number(this.invoice_amount_due)
      ).toFixed(2);
    },
  },
  mounted() {
    this.customer_email = this.invoice.customer.business_email;
    this.customer_phone = this.invoice.customer.business_phone;
    this.amount = 0;
  },
};
</script>

<style scoped></style>