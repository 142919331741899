<template>
  <v-row dense>
    <v-col
        cols="12"
        sm="4"
        v-for="item in items"
        :key="item.id"
    >
      <v-list-item
          :to="'/customers/'+item.id"
          class="border"
          style="text-decoration: none;"
          @click="$emit('close'); $router.push('/customers/'+item.id)"
      >
        <v-list-item-avatar><v-img src="/img/photo.png"></v-img></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{item.business_name}}</v-list-item-title>
          <v-list-item-subtitle v-if="item.business_phone">Phone Number:{{item.business_phone}}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.business_address">Address:{{item.business_address}}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.balance" class="green--text">Owes:{{item.balance  | currency_symbol}}</v-list-item-subtitle>
         </v-list-item-content>
      </v-list-item>

    </v-col>
    <v-col cols="12" sm="4">
      <v-card outlined tile>
        <v-card-text>
          <v-btn
              large
              text
              rounded
              color="blue"
              to="/customers"
              block
              @click="$emit('close'); $router.push('/customers')"
          >Go to customers <v-icon>mdi-arrow-right</v-icon></v-btn>

        </v-card-text>
      </v-card>
    </v-col>

  </v-row>

</template>

<script>
export default {
  props:['items'],
  name: "SearchCustomers"
}
</script>

<style scoped>

</style>