<template>
<v-dialog width="500" v-model="show" persistent>
  <v-card color="blue darken-4" dark>
      <v-stepper v-model="step" style="box-shadow: none; background-color: transparent">
        <v-stepper-items>
          <v-stepper-content step="1" class="text-center">
            <h3>Introducing,</h3>
            <h1 class="font-weight-black">Bank reconciliation</h1>
            <p>Easily align your bank statements with your bank transactions on Built.</p>
          <v-img src="/img/bank_recon_screen.png"></v-img>
            <p class="font-weight-black w-100 text-left mt-3">Steps</p>

            <p class="w-100 text-left">

            <ol>
              <li>Select "Accounting" from the main menu.</li>
              <li>From the options available, select the "Bank reconciliation" card.</li>
              <li>From the list of bank accounts, select an account of your choice.</li>
              <li>Create a new period as shown on your bank statement.</li>
              <li>Create,review,edit or delete transactions until the statement balance is the same as the balance on Built.</li>
            </ol>

            </p>

            <v-row dense>

              <v-col cols="12" sm="6" @click="dismiss">
                <v-btn outlined block dark>dismiss<v-icon>mdi-close</v-icon></v-btn>
              </v-col>

              <v-col cols="12" sm="6">
                <v-btn outlined dark block @click="chekcout">Check it out<v-icon>mdi-arrow-right</v-icon></v-btn>
              </v-col>


            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "BankReconIntro",
  data(){
    return{
      show:false,
      step:1
    }
  },
  methods:{
    dismiss(){
      localStorage.setItem("reconIntro","true");
      this.show=false;
    },
    chekcout(){
      localStorage.setItem("reconIntro","true");
      this.show=false;
      this.$router.push({
        path:'/bank-reconciliation'
      });
    }
  },
  mounted() {

    this.show= !Boolean(localStorage.getItem("reconIntro"));
  }
}
</script>

<style scoped>

</style>