<template>
    <v-list-item
            :to="'/sync/expense/'+this.item.id+'?syncid='+item.id + '&page=' + currentPage"
            color="green"
            two-line class="border-bottom"
            @click="selectItem(item)"
            style="text-decoration: none"

    >
        <v-list-item-content>
            <v-list-item-title class="text-muted">{{item.description}}</v-list-item-title>
            <v-list-item-subtitle>
                 Created at {{item.created_at}}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
               {{item.record_count}} Expenses |  Total amount {{item.total_amount | toMoney | currency_symbol}}
            </v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-icon>
            <v-icon>mdi-chevron-right</v-icon>
        </v-list-item-icon>
    </v-list-item>
</template>

<script>
    export default {
        props:['item'],
        name: "ProcessedExpenseItem",
        methods:{

            selectItem(item){


                this.$emit('itemSelected',item);
                this.$emit('close');
            }

        },
        computed: {
            currentPage() {
                return this.$route.query.page ? this.$route.query.page : 1;
            }
        }
    }
</script>

<style scoped>

</style>
