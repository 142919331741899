<template>
  <v-card>
    <v-card-title class="font-weight-light"
      >Send receipts on invoice({{ invoice.invoice_number }}) via SMS
      <v-spacer></v-spacer>
      <v-btn
        color="red lighten-5 red--text"
        rounded
        depressed
        small
        @click="$emit('closed')"
        >close <v-icon>mdi-close</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-alert type="info" color="blue lighten-5 blue--text">
        To guarantee delivery, ensure To select the right country code.<br />
        Do not add a leading 0 to the phone number eg. enter something like
        <strong>23350xxxxxxx</strong> not <strong>233050xxxxxxx</strong>
      </v-alert>
      <phone-number-input
        :isEmailLogin="true"
        @input="(n) => (user_number = n)"
        :key="invoice.uuid"
        :default_number="phone_number"
      ></phone-number-input>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :loading="sending"
        @click="send"
        color="blue darken-4"
        dark
        rounded
        block
        depressed
        >Send SMS</v-btn
      >
    </v-card-actions>
    <v-snackbar v-model="success">{{ success_message }}</v-snackbar>
    <v-snackbar v-model="error" color="red" dark>{{
      error_message
    }}</v-snackbar>
  </v-card>
</template>

<script>
import PhoneNumberInput from "../agents/PhoneNumberInput";

export default {
  name: "SendReceiptsSMS",
  components: { PhoneNumberInput },
  props: ["invoice"],
  data() {
    return {
      phone_number: "",
      sending: false,
      user_number: "",
      success_message: "",
      success: false,
      error: false,
      error_message: "",
    };
  },
  computed: {
    invoiceID() {
      return this.invoice.id;
    },
  },
  watch: {
    invoiceID() {
      this.phone_number = this.invoice.customer.business_phone;
    },
  },
  methods: {
    send() {
      this.sending = true;

      const formdata = new FormData();
      formdata.append("recipient_number", this.user_number);
      formdata.append("invoice_id", this.invoice.uuid);

      axios
        .post("/api/send/invoice/receipt/sms", formdata)
        .then((res) => {
          this.success_message = "Invoice sent to " + this.user_number;
          this.success = true;
          this.sending = false;
          this.$emit("closed");
        })
        .catch((error) => {
          this.error_message =
            "Something went wrong, we could not send the invoice, please try again";
          this.error = true;
          this.sending = false;
        });
    },
  },
  mounted() {
    this.phone_number = this.invoice.customer.business_phone;
  },
};
</script>

<style scoped>
</style>