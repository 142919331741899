<template>
    <div>
        <v-list three-line v-if="creditors.length >0">
            <template v-for="(creditor, index) in creditors">


                <v-list-item
                    :key="creditor.id"
                    link

                    v-if="index <=7"
                >
                    <v-list-item-avatar>
                        <v-img src="/img/photo.png"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn text color="blue" :to="'/suppliers/'+creditor.id" target="_blank" >{{creditor.supplier_name}}<v-icon>mdi-arrow-top-right</v-icon></v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-chip color="red lighten-5" dark class="font-weight-bold text-danger" >{{creditor.balance | currency_symbol}}</v-chip>
                </v-list-item>

            </template>

            <template>
                <v-list-item>
                    <v-btn rounded block text color="blue" to="/suppliers"> View All</v-btn>
                </v-list-item>
            </template>
        </v-list>

        <center class="mt-5" v-else>
            <v-icon x-large class="text-muted mt-5">mdi-account-off-outline</v-icon>
            <h4>You have no suppliers at the moment</h4>
            <v-btn small rounded text color="blue" to="/suppliers" @click="$store.state.new_supplier=true" >Create supplier</v-btn>
        </center>
    </div>
</template>

<script>
    export default {
        name: "CreditorsComponent",
        props:['creditors']
    }
</script>

<style scoped>

</style>
