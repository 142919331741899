<template>
    <v-row>
      <v-col cols="12">
        <v-card flat>

          <p class="display-2 text-center">
            {{total | short_number | currency_symbol}}
          </p>

        <v-list three-line v-if="inventory_items.length >0">
            <template v-for="(inventory, index) in inventory_items">
                <v-list-item
                    :key="inventory.id"
                    v-if="index <=7"
                    link
                >
                    <v-list-item-avatar>
                        <v-icon>mdi-cube-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn class="p-0" target="_blank" text color="blue" :to="'/inventory/'+inventory.id">{{inventory.name}}<v-icon>mdi-arrow-top-right</v-icon></v-btn>

                        </v-list-item-title>
                        <v-list-item-subtitle>Quantity: {{inventory.quantity}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-chip color="grey lighten-4" class="font-weight-bold" >{{inventory.balance | currency_symbol}}</v-chip>
                </v-list-item>
            </template>

            <template>
                <v-list-item>
                    <v-btn rounded block text color="blue"  to="/inventory"> View All</v-btn>
                </v-list-item>
            </template>
        </v-list>
        <center class="mt-5" v-else>
            <v-icon x-large class="text-muted mt-5">mdi-cart-off</v-icon>
            <h4>You have no inventory items at the moment</h4>
            <v-btn small rounded text color="blue" to="/inventory" @click="$store.state.new_item=true" >Create product or service</v-btn>
        </center>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "InventoryComponent",
        props:['inventory_items','total']
    }
</script>

<style scoped>

</style>
