import { render, staticRenderFns } from "./SendSMSCOmponent.vue?vue&type=template&id=670a1a97&scoped=true"
import script from "./SendSMSCOmponent.vue?vue&type=script&lang=js"
export * from "./SendSMSCOmponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670a1a97",
  null
  
)

export default component.exports