<template>
    <v-row>
        <v-col cols="12" sm="12" v-if="tag_list.length>0">
         <strong v-if="show_title" class="font-weight-bolder">Tags:</strong>   <v-btn text color="blue" small v-for="(tag,index) in tag_list" :key="index" :to="'/tag/'+tag">#{{tag}}</v-btn>
        </v-col>
    </v-row>
    
</template>

<script>
    export default {
        props:{
            tags:{
                default:'',
                type:String
            },
            show_title:{
                default: true,
                type:Boolean
            }
        },
        name: "anchorComponent",
        computed:{
            tag_list(){
                return this.tags ? this.tags.split(',') : '';
            }

        }
    }
</script>

<style scoped>

</style>