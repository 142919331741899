import { render, staticRenderFns } from "./NewBusinessComponent.vue?vue&type=template&id=5861c94c&scoped=true"
import script from "./NewBusinessComponent.vue?vue&type=script&lang=js"
export * from "./NewBusinessComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5861c94c",
  null
  
)

export default component.exports