<template>
<div>
      <div class="alert alert-info" :class="$vuetify.theme.isDark ? 'bg-dark': ''" v-if="invite_customers_banner">
                <v-row dense>
                    <v-col cols="12" sm="8">
                        <h4 class="font-weight-light"> {{ $t('affiliate.invite_customer_supplier_title') }}
                        </h4>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-btn color="success" @click="show_customers_invite = true;" dark> {{ $t('affiliate.start')}}</v-btn>
                    </v-col>
                    <v-col cols="12" sm="1" class="text-right">
                        <v-btn icon @click="invite_customers_banner = false;">
                            <v-icon class="text-muted">mdi-close-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

    <v-dialog
            v-model="show_customers_invite" width="500"
            scrollable
            persistent
            :fullscreen="$vuetify.breakpoint.mobile">

        <v-card>

            <v-card-title>
                 <v-btn small depressed color="red lighten-5 red--text" rounded @click="show_customers_invite=false">{{ $t("main.close") }} <v-icon>mdi-close</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn small depressed color="blue darken-4 white--text" @click="e1='2'" v-if="e1==1">SKip<v-icon>mdi-arrow-right</v-icon></v-btn>
                <v-btn small depressed color="green darken-4 white--text" @click="invite_customers_banner = false; show_customers_invite = false;" v-if="e1==2">DONE</v-btn>

            </v-card-title>

            <v-card-text>
                <v-stepper v-model="e1" style="box-shadow: none">
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <h3>Invite your customers to use built</h3>

                            <v-list v-if="customers.length">
                            <v-list-item  class="border-bottom" :class="customer.selected ? 'green--text' : ''" v-for="customer in customers" :key="customer.id">
                                <v-list-item-avatar>
                                <v-img src="/img/photo.png"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title :class="customer.selected ? 'green--text' : ''">{{customer.business_name}}</v-list-item-title>
                                <v-list-item-subtitle :class="customer.selected ? 'green--text' : ''">{{customer.business_email}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                <v-checkbox
                                        v-model="customer.selected"
                                        color="green"
                                ></v-checkbox>
                                </v-list-item-action>
                            </v-list-item>

                            </v-list>
                            <v-list-item v-else>You have no customers, Please <a href="/customers" class="px-1"> create </a> customers to invite them</v-list-item>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <h3>Invite your suppliers to use built</h3>
                            <v-list v-if="suppliers.length">
                            <v-list-item  class="border-bottom" :class="supplier.selected ? 'green--text' : ''" v-for="supplier in suppliers" :key="supplier.id">
                                <v-list-item-avatar>
                                <v-img src="/img/photo.png"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                <v-list-item-title :class="supplier.selected ? 'green--text' : ''">{{supplier.supplier_name}}</v-list-item-title>
                                <v-list-item-subtitle :class="supplier.selected ? 'green--text' : ''">{{supplier.supplier_email}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                <v-checkbox
                                        v-model="supplier.selected"
                                        color="green"
                                ></v-checkbox>
                                </v-list-item-action>

                            </v-list-item>

                            </v-list>
                            <v-list-item v-else>You have no suppliers, Please <a href="/suppliers" class="px-1"> create </a> suppliers to invite them</v-list-item>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>

            </v-card-text>
            <v-card-actions>

                <v-btn
                        v-show="selectedSuppliers.length>0"
                        large color="blue darken-4"
                        dark
                        depressed
                        block
                        rounded
                        @click="inviteSuppliers"
                        :loading="loading"
                        v-if="e1==2"

                >Invite {{selectedSuppliers.length}} supplier(s)</v-btn>
                <v-btn
                        v-show="selectedCustomers.length>0"
                        large color="blue darken-4"
                        dark
                        depressed
                        block
                        rounded
                        @click="inviteCustomers"
                        :loading="loading"
                        v-if="e1==1"

                >Invite {{selectedCustomers.length}} customer(s)</v-btn>
            </v-card-actions>
            <v-snackbar v-model="suppliers_invited">
                Suppliers invited successfully!
            </v-snackbar>
            <v-snackbar v-model="customers_invited">
                Customers invited successfully!
            </v-snackbar>
        </v-card>
    </v-dialog>

</div>
</template>
<script>
export default {
    name:"CustomerSupplierInvite",
    data() {
        return {
            customers: [],
            suppliers: [],
            e1: 1,
            loading: false,
            suppliers_invited: false,
            customers_invited: false,

            show_customers_invite: false,
            invite_customers_banner: false,
        }
    },
    methods: {
        getCustomers() {
        this.progress = true;
        axios
            .get("/api/getuninvitedcustomers")
            .then((res) => {
            let customersWithEmail = res.data.customers.filter(customer => customer.business_email);
            let newCustomerList = [];
            if (customersWithEmail.length) {
                customersWithEmail.forEach(customer => {
                    newCustomerList.push({...customer, selected: true })
                });
            }
            this.customers = newCustomerList;
        })
        .catch(err => console.log(err));
    },
     get_suppliers(){
                axios.get('/api/getuninvitedsuppliers')
                    .then(suppliers=>{
                        let suppliersWithEmail = suppliers.data.suppliers.filter(supplier => supplier.supplier_email);
                        let newSupplierList = [];

                        if (suppliersWithEmail.length) {
                            suppliersWithEmail.forEach(supplier => {
                                newSupplierList.push({...supplier, selected: true})
                            });
                        }
                        this.suppliers = newSupplierList;
                    })
                    .catch(err => console.log(err));
            },
        inviteSuppliers() {
            this.loading = true;
            const supplierIds = [];
            this.selectedSuppliers.forEach(supplier => {
                supplierIds.push(supplier.id);
            })
            axios.post('/api/invitesuppliers',   {supplier_ids: supplierIds})
                .then(res => {

                    this.loading = false;
                    this.$emit('close');
                    // this.show_customers_invite = false;
                    this.invite_customers_banner = false;
                    this.suppliers_invited = true;
                })
                .catch(err => {
                    this.loading = false;
                })
        },
        inviteCustomers() {
            this.loading = true;
            const customerIds = [];
            this.selectedCustomers.forEach(customer => {
                customerIds.push(customer.id);
            });

            axios.post('/api/invitecustomers', {customer_ids: customerIds})
                .then(res => {
                    this.customers_invited = true;
                    this.loading = false;
                    this.e1 = 2;
                })
                .catch(err => {
                    this.loading = false;
                })
        },

        showSuppliersInviteDialog() {
            axios.get('/api/showinvitesuppliersdialog')
                .then(res => {

                    this.suppliers = res.data.suppliers;

                    if (this.suppliers.length) {
                        let newSupplierList = [];
                        this.suppliers.forEach(supplier => {
                            newSupplierList.push({...supplier, selected: true})
                        });
                        this.suppliers = newSupplierList;
                        if (!this.invite_customers_banner) {
                            this.invite_customers_banner = res.data.show;
                            this.e1=2;
                        }
                    }

                })
        },
        showCustomersInviteDialog() {
            axios.get('/api/showinvitecustomersdialog')
                .then(res => {
                     this.customers = res.data.customers;

                    if (this.customers.length) {

                        let newCustomerList = [];
                        this.customers.forEach(customer => {
                            newCustomerList.push({...customer, selected: true})
                        });
                        this.customers = newCustomerList;
                        if (!this.invite_customers_banner) {
                            this.invite_customers_banner = res.data.show;
                        }

                    }

                    this.showSuppliersInviteDialog();

                })
        }
    },
    created() {
        //this.showCustomersInviteDialog();
    },

    computed: {
        selectedCustomers() {
            return this.customers.filter(customer => customer.selected);
        },
        selectedSuppliers() {
            return this.suppliers.filter(supplier => supplier.selected);
        }
    }
}
</script>
