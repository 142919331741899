<template>

        <v-card>
            <v-card-title class="font-weight-light">
                     {{ $t("main.send_reminder") }} <v-spacer></v-spacer>
                        <v-btn @click="$emit('close_dialog')"
                            color="red lighten-5 red--text" rounded depressed>{{ $t("main.close") }}<v-icon>mdi-close</v-icon></v-btn>
                    </v-card-title>
                <v-card-text class="pt-4">
                    <v-form ref="manual_reminder_form">
                        <v-text-field
                        label="Customer Email"
                        outlined
                        v-model="customerEmail"
                        :rules="[v => !!v || this.$t('main.email_required')]"
                    ></v-text-field>

                    <v-text-field
                        label="Subject"
                        outlined
                        v-model="subject"
                        :rules="[v => !!v || this.$t('main.subject_required')]"
                    ></v-text-field>
                    <v-textarea
                    rows="2"
                    auto-grow
                        outlined
                        label="Message"
                        v-model="message"
                        :rules="[v => !!v || this.$t('main.message_required')]"
                    ></v-textarea>
                </v-form>
                </v-card-text>
            <v-card-actions>
                <v-btn dark depressed  rounded large color="blue darken-4" block @click="sendReminder" :loading="progress">
                    SEND reminder
                </v-btn>

            </v-card-actions>
            </v-card>
</template>
<script>
export default {
    props: ['customerEmail', 'customerName', 'invoice'],
    data() {
        return {
            message: '',
            subject: '',
            error_msg:'',
            error_message:false,
            success_msg:'',
            success_message:false,
            progress: false,
        }
    },
    computed:{
      business(){
          return this.$store.state.user.user_infor.current_business;
      }
    },
    mounted() {
        // formatted this way for display
        this.message = `Hi ${this.customerName},

I hope you are well. We are yet to receive payment from yourselves of ${this.invoice.fx_currency} ${this.invoice.amount_due} in respect of our invoice ${this.invoice.invoice_number} which is due on ${this.invoice.end_date}.

I would be grateful if you could let me know when we can expect to receive payment.

Best regards,
${this.invoice.sender.first_name}
`;
        this.subject = `${this.business.name}: Invoice ${this.invoice.invoice_number}`
},
    methods: {
        sendReminder() {
            if (this.$refs.manual_reminder_form.validate()) {

                const data = {
                    email: this.customerEmail,
                    subject: this.subject,
                    message: this.message,
                    invoice_id: this.invoice.id
                };

                this.progress = true;
                axios.post('/api/sendmanualinvoicereminder', data)
                    .then(response => {
                        this.$emit('success');
                        this.success_message = true;
                        this.success_msg = 'Reminder sent successfully!';
                        this.$emit('close_dialog');
                        this.progress = false;
                    })
                    .catch(err => {
                        this.error_message = true;
                        this.progress = false;
                        this.$emit('error');
                    })
            }
        }
    }

}
</script>
