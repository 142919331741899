<template>
<span>
  <v-tooltip bottom>
    <template v-slot:activator="{on}">

  <v-btn v-on="on" @click="findRelation" :loading="progress" icon x-small color="blue"><v-icon>mdi-anchor</v-icon></v-btn>
    </template>
   Find other related transactions
  </v-tooltip>

  <v-dialog  transition="dialog-bottom-transition" scrollable persistent v-model="showDialog" width="70%" :fullscreen="$vuetify.breakpoint.mobile">

    <v-card>
      <v-card-title>Transactions group <v-spacer></v-spacer> <v-btn small color="red lighten-5 red--text" depressed rounded @click="showDialog=false">close<v-icon>mdi-close</v-icon></v-btn></v-card-title>

      <v-card-text v-if="results" style="height: 100px"  >
        <h2>Related transactions found</h2>
        <strong>Transaction type:</strong> {{results.meta_data.type}} <resolve-ledger-component :ledger="transaction" ></resolve-ledger-component>

      </v-card-text>


      <v-card-text>


        <v-simple-table v-if="results">
          <thead>
          <tr>
            <th>Date</th>
            <th>Account</th>
            <th>Debit Amount</th>
            <th>Credit Amount</th>
            <th>Description</th>
            <th>Tag</th>
          </tr>
          </thead>
          <tbody>
          <tr
          v-for="item in results.group"
          :key="item.id"
          >
            <td>{{item.entry_date | humanDate}}</td>
            <td><account-button :account="item.account"></account-button></td>
            <td>

              <span v-if="Number(item.debit_amount)==0">-</span>
              <span v-else>
              {{item.debit_amount | toMoney | currency_symbol}}

              </span>

            </td>
            <td>
            <span v-if="Number(item.credit_amount)==0">-</span>
              <span v-else>
              {{item.credit_amount | toMoney | currency_symbol}}

              </span>
            </td>
            <td>{{item.description}}</td>
            <td>{{item.tag}}</td>

          </tr>
          </tbody>
        </v-simple-table>

      </v-card-text>

      <v-card-actions>
          <h3>Total Debits:<strong>{{totalDebits | toMoney}}</strong></h3> &nbsp;|&nbsp;
        <h3>Total Credits:<strong>{{totalCredits | toMoney}}</strong></h3>
      </v-card-actions>
    </v-card>

  </v-dialog>

  <v-snackbar color="error" v-model="error">{{error_message}}</v-snackbar>
</span>

</template>

<script>
import axios from "axios";
import AccountButton from "./accountButton";
import ResolveLedgerComponent from "./ResolveLedgerComponent";

export default {
  components: {ResolveLedgerComponent, AccountButton},
  props: ['transaction'],
  name: "transactionAnchorComponent",
  data() {
    return {
      results: null,
      progress: false,
      showDialog:false,
      error:false,
      error_message:""
    }
  },
  computed:{
    totalDebits(){
      let sum=0;
      if (this.results){

        this.results.group.forEach(item=>{

          sum+=Number(item.debit_amount);

        })

      }

      return sum.toFixed(2);

    } ,
    totalCredits(){
      let sum=0;
      if (this.results){

        this.results.group.forEach(item=>{

          sum+=Number(item.credit_amount);

        })

      }

      return sum.toFixed(2);

    }
  },
  methods: {

    findRelation() {
      this.progress = true;
      axios.get("/api/transaction/group/" + this.transaction.id)
          .then(res => {
            this.results=res.data;

            this.progress = false;
            this.showDialog=true;
          })
      .catch(error=>{
        this.error_message = error.response.status===302?error.response.data :"Something went wrong could not retrieve data";
      this.error=true;
      this.progress=false;
      })


    }


  }
}
</script>

<style scoped>

</style>