<template>
  <span>
    <v-row dense>
      <v-col class="mx-auto" cols="12" sm="10">
        <v-card flat style="margin-top: 10%" tile>
          <v-stepper
            v-model="step"
            style="box-shadow: none; padding: 0 !important"
          >
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card-text class="mb-5 mt-5">
                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-avatar>
                        <v-img
                          :src="`${$store.state.user.user_infor.current_business.logo}`"
                          alt="logo"
                          contain
                        ></v-img>
                      </v-avatar>

                      Hi there,
                    </v-col>

                    <v-col cols="12" sm="8"> </v-col>

                    <v-col class="text-right" cols="12" sm="2">
                      <v-btn
                        :color="$vuetify.theme.isDark ? 'white' : 'red'"
                        rounded
                        text
                        @click="skipOnboarding"
                        >Skip Onboarding <v-icon>mdi-close</v-icon></v-btn
                      >
                    </v-col>

                    <v-col cols="12" sm="12">
                      <h1
                        :class="
                          $vuetify.theme.isDark ? 'white--text' : 'text-built'
                        "
                        class="text-center font-weight-bold"
                      >
                        Who do we have here today?
                      </h1>
                      <h3
                        :class="
                          $vuetify.theme.isDark ? 'white--text' : 'text-built'
                        "
                        class="text-center font-weight-light"
                      >
                        Which of these best describe you?
                      </h3>
                      <p class="text-center font-weight-light text--disabled">
                        This helps us show you what to import to fully onboard
                        you
                      </p>
                    </v-col>
                  </v-row>

                  <div class="pa-6">
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-card
                          :loading="loading"
                          :disabled="loading"
                          outlined
                          @click="step = 2"
                        >
                          <v-card-text class="text-center">
                            <v-icon
                              :class="
                                $vuetify.theme.isDark
                                  ? 'white--text'
                                  : 'text-built'
                              "
                              style="font-size: 150px"
                              x-large
                              >mdi-account</v-icon
                            >
                            <h2
                              :class="
                                $vuetify.theme.isDark
                                  ? 'white--text'
                                  : 'text-built'
                              "
                              class="font-weight-bold"
                            >
                              Business owner or <br />Employee
                            </h2>
                            <small class="text--disabled"
                              >Basic setup for users without accounting
                              knowledge</small
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" sm="4">
                        <v-card
                          :loading="loading"
                          :disabled="loading"
                          outlined
                          @click="step = 3"
                        >
                          <v-card-text class="text-center">
                            <v-icon
                              :class="
                                $vuetify.theme.isDark
                                  ? 'white--text'
                                  : 'text-built'
                              "
                              style="font-size: 150px"
                              x-large
                              >mdi-book-open-variant</v-icon
                            >
                            <h2
                              :class="
                                $vuetify.theme.isDark
                                  ? 'white--text'
                                  : 'text-built'
                              "
                              class="font-weight-bold"
                            >
                              Bookkeeper or <br />Accountant
                            </h2>
                            <small class="text--disabled"
                              >Advanced setup for users with accounting
                              knowledge</small
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" sm="4">
                        <v-card
                          :loading="loading"
                          :disabled="loading"
                          outlined
                          @click="step = 4"
                        >
                          <v-card-text class="text-center">
                            <v-icon
                              :class="
                                $vuetify.theme.isDark
                                  ? 'white--text'
                                  : 'text-built'
                              "
                              style="font-size: 150px"
                              x-large
                              >mdi-application-import</v-icon
                            >
                            <h2
                              :class="
                                $vuetify.theme.isDark
                                  ? 'white--text'
                                  : 'text-built'
                              "
                              class="font-weight-bold"
                            >
                              Account <br />
                              Migration
                            </h2>
                            <small class="text--disabled"
                              >For users moving from other supported bookkeeping
                              apps</small
                            >
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="10"> </v-col>
                      <v-col cols="2">
                        <v-img
                          v-if="$vuetify.theme.isDark"
                          class="ma-4"
                          contain
                          height="20"
                          src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/logo2.webp"
                        ></v-img>
                        <v-img
                          v-else
                          class="ma-4"
                          contain
                          height="20"
                          src="/img/app_name.png"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-stepper-content>

              <v-stepper-content step="2">
                <business-owner-onboaring-component
                  v-if="!loading"
                  :default-cash_bank="defaultCash_bank"
                  :default-customers="defaultCustomers"
                  :default-items="defaultItems"
                  :default-suppliers="defaultSuppliers"
                  :finished="finished"
                  @back="step = step - 1"
                ></business-owner-onboaring-component>
              </v-stepper-content>

              <v-stepper-content step="3">
                <accounting-onboaring-component
                  v-if="!loading && step == 3"
                  :default-trial-balance="defaultTrial_balance"
                  :finished="finished"
                  @back="step = step - 2"
                ></accounting-onboaring-component>
              </v-stepper-content>

              <v-stepper-content step="4">
                <account-migration
                  v-if="step == 4"
                  :default-app="appName"
                  :trial-balance="defaultTrial_balance"
                  :default-suppliers="defaultSuppliers"
                  :default-customers="defaultCustomers"
                  :defautl-items="defaultItems"
                  :default-bank-accounts="defaultCash_bank"
                  @back="step = step - 3"
                  :finish="finished"
                ></account-migration>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import BusinessOwnerOnboaringComponent from "./BusinessOwnerOnboaringComponent";
import axios from "axios";
import AccountingOnboaringComponent from "./AccountingOnboaringComponent";
import AccountMigration from "./accountMigration/accountMigration";

export default {
  props: ["items", "cash_bank", "customers", "suppliers"],
  name: "MainOnboardingComponent",
  components: {
    AccountMigration,
    AccountingOnboaringComponent,
    BusinessOwnerOnboaringComponent,
  },
  data() {
    return {
      step: 1,
      defaultItems: [],
      defaultCustomers: [],
      defaultSuppliers: [],
      defaultCash_bank: [],
      defaultTrial_balance: [],
      loading: false,
      finished: false,
      appName: "",
    };
  },
  computed: {
    baseUrl() {
      return this.$store.state.baseURL;
    },
  },
  methods: {
    getState() {
      this.loading = true;
      axios
        .get("/api/onboard/state")
        .then((res) => {
          const data = res.data;

          if (Boolean(data)) {
            this.defaultItems = data.items;
            this.defaultCustomers = data.customers;
            this.defaultSuppliers = data.suppliers;
            this.defaultCash_bank = data.cash_bank;
            this.defaultTrial_balance = data.trial_balance;
            this.finished = data.finished;

            if (data.user_type.toLowerCase() === "business_owner") {
              this.step = 2;
            }

            if (data.user_type.toLowerCase() === "accountant") {
              this.step = 3;
            }

            if (
              ["wave", "xero", "quickbooks"].includes(
                data.user_type.toLowerCase()
              )
            ) {
              this.step = 4;
              this.appName = data.user_type.toLowerCase();
            }
          } else {
            this.defaultItems = [];
            this.defaultCustomers = [];
            this.defaultSuppliers = [];
            this.defaultCash_bank = [];
            this.defaultTrial_balance = [];
          }

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    skipOnboarding() {
      this.$store.state.showOnboarding = false;

      window.location = "/";
    },
  },
  mounted() {
    this.getState();
  },
};
</script>

<style scoped></style>
