<template>
  <v-card>
    <v-card-title class="font-weight-light" v-if="businessSwitch"
      >Create a new business
      <v-spacer></v-spacer>
      <v-btn
        color="red lighten-5 red--text"
        depressed
        rounded
        small
        @click="$emit('closeDialog')"
        >close
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title v-else>
      <v-row>
        <v-col cols="12" sm="4">
          <v-btn rounded color="blue" text @click="$emit('back')">
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-col>
        <v-col cols="12" sm="8" class="text-right">
          <span class="font-weight-light">Create a new business</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-progress-linear
        indeterminate
        color="blue"
        v-if="isSaving"
      ></v-progress-linear>
      <v-form ref="businessForm">
        <v-row dense>
          <v-col cols="12" sm="6">
            <photo-selector-component @updated="logo=>logo_path=logo"></photo-selector-component>

          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              outlined
              label="Business Name"
              v-model="businessModel.name"
              :rules="rules"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              type="email"
              outlined
              label="Business E-Mail"
              v-model="businessModel.email"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              dense
              type="tel"
              outlined
              label="Business tel number"
              v-model="businessModel.phone_number"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Invoice Prefix"
              v-model="businessModel.invoice_id"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              :items="industries"
              item-value="id"
              item-text="name"
              outlined
              label="Industry"
              v-model="businessModel.industry_id"
              persistent-hint
              :rules="rules"
              :loading="getting_industries"
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              outlined
              label="Currency"
              item-text="code"
              item-value="id"
              :loading="getting_currencies"
              :items="currencies"
              hint="Once selected, the currency of a business cannot be changed"
              persistent-hint
              :rules="rules"
              v-model="businessModel.currency_id"
              dense
            >
              <template v-slot:item="data">
                {{ data.item.country }} - {{ data.item.currency }} ({{
                  data.item.code
                }})
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              :items="countries"
              item-value="id"
              item-text="name"
              outlined
              :loading="getting_countries"
              label="Country"
              v-model="businessModel.country_id"
              :rules="rules"
              dense
            >
              <template v-slot:item="{ item }">
                <flag class="mr-2" :iso="item.code"></flag>
                {{ item.name }}
              </template>
            </v-autocomplete>
          </v-col>

          <!--REGION-->
          <v-col cols="12" sm="12">
            <v-select
              :loading="loadingRegion"
              dense
              outlined
              v-model="region"
              :items="regions"
              item-text="name"
              item-value="id"
              label="State/Region"
              :disabled="loadingRegion"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12">
            <v-select
                dense
                outlined
                v-model="businessModel.employee_size"
                :rules="$store.state.requiredRule"
                :items="sizes"
                label="Business Size"
            ></v-select>
          </v-col>

        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        large
        rounded
        color="blue darken-4"
        dark
        @click="createBusiness"
        :loading="isSaving"
        >Create Business
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { PREVIEW_URL } from "../../data";
import PhotoSelectorComponent from "./PhotoSelectorComponent.vue";

export default {
  name: "NewBusinessComponent",
  components: {PhotoSelectorComponent},
  props: ["businessSwitch"],
  data() {
    return {
      isSaving: false,
      previewURL: PREVIEW_URL,
      hasErrorMsg: false,
      hasSuccessMsg: false,
      hasImageError: false,
      successMsg: "",
      errorMsg: "",
      businessModel: {
        name: "",
        email: "",
        phone_number: "",
        invoice_id: "",
        account_name: "",
        account_number: "",
        bank_name: "",
        country_id: "",
        region_id: "",
        employee_size: "",
        currency_id: "",
        industry_id: "",
        logo: null,
      },
      logo_path:"",
      size: "",
      sizes: [
        {
          value: "less_than_5_employees",
          text: "Less than 5 employees"
        },
        {
          value: "5_to_10_employees",
          text: "5 to 10 employees"
        },
        {
          value: "10_to_30_employees",
          text: "10 to 30 employees"
        },

        {
          value: "30_to_50_employees",
          text: "30 to 50 employees"
        },
        {
          value: "More_than_50_employees",
          text: "More than 50 employees"
        },

      ],
      isSelecting: false,
      industries: [],
      countries: [],
      currencies: [],
      getting_industries: false,
      getting_currencies: false,
      getting_countries: false,
      rules: [(value) => !!value || "Field is required"],
      regions: [],
      region: null,
      loadingRegion: false,
    };
  },
  computed: {},
  methods: {
    get_currencies() {
      this.getting_currencies = true;
      axios.get("/api/getcurrencies").then((res) => {
        this.currencies = res.data;
        this.getting_currencies = false;
      });
    },
    get_countries() {
      this.getting_countries = true;
      axios.get("/api/getcountries").then((res) => {
        this.countries = res.data;
        this.getting_countries = false;
      });
    },
    get_industries() {
      this.getting_industries = true;
      axios.get("/api/getindustries").then((res) => {
        this.industries = res.data;
        this.getting_industries = false;
      });
    },
    selectBusinessLogo() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onLogoChange(e) {
      this.hasImageError = false;
      const MAX_SIZE = 532598;
      const image = e.target.files[0];
      this.businessModel.logo = image;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        this.previewURL = reader.result;
      };
    },
    async createBusiness() {
      if (this.$refs.businessForm.validate()) {
        this.isSaving = true;
        const formData = new FormData();
        Object.keys(this.businessModel).forEach((key) => {
          formData.append(key, this.businessModel[key]);
        });
        formData.append("active", 1);
        formData.append("is_retail", 0);
        formData.append("is_service", 1);
        try {
          await axios.post("/api/createbusiness", formData);
          this.addDialog = false;
          this.isSaving = false;

          this.$router.push({ path: "/" });
          location.reload();
        } catch (err) {
          this.hasErrorMsg = true;
          this.errorMsg = "Something went wrong, please check and try again";
        }
      }
    },
    getRegions(id) {
      this.loadingRegion = true;
      let url = `/api/getregions?country_id=${id}`;
      axios.get(url).then((response) => {
        this.regions = response.data;
        this.loadingRegion = false;
      });
    },
  },
  mounted() {
    this.get_industries();
    this.get_currencies();
    this.get_countries();
    this.businessModel.country_id =
      this.$store.state.user.user_infor.current_business.country_id;
  },
  watch: {
    "businessModel.country_id"() {
      this.getRegions(this.businessModel.country_id);
    },
    region(newOne) {
      this.businessModel.region_id = newOne;
    },
  },
};
</script>

<style scoped></style>
