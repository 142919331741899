<template>
  <div>
    <v-overlay opacity="0.9" absolute v-if="$store.state.momo.progress">
      <center v-if="!$store.state.momo.requestPendding">
        <v-progress-circular color="white" size="50" width="4" indeterminate>
        </v-progress-circular>

        <strong class="text-white"
          >Transaction in progress, do not close this dialog or page</strong
        >
      </center>
      <center v-else>
        <h4 v-if="network == 'MTN'" class="yellow--text font-weight-bold">
          Dial *170#
        </h4>
        <h4 v-if="network == 'MTN'" class="yellow--text font-weight-bold">
          Select 6 - My Wallet
        </h4>
        <h4 v-if="network == 'MTN'" class="yellow--text font-weight-bold">
          Select 3 - My Approvals
        </h4>

        <v-progress-circular color="white" size="30" width="4" indeterminate>
        </v-progress-circular>
        <br />

        <strong class="text-white m-2"
          >Waiting for payment authorisation, <br />
          transaction would continue automatically
        </strong>

        <v-btn
          class="d-block mt-5"
          rounded
          outlined
          color="red"
          v-if="business.country.code === 'RW'"
          @click="$store.state.momo.progress = false"
          >Cancel
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </center>
    </v-overlay>

    <v-card-text v-if="business.country.code === 'GH'">
      <v-stepper class="p-0" v-model="step" style="box-shadow: none !important">
        <v-stepper-items>
          <v-stepper-content step="1" class="p-0">
            <v-row dense>
              <v-col cols="12" class="mt-2">
                <v-select
                  :items="networks"
                  label="Select Network Operator"
                  v-model="network"
                  outlined
                  :disabled="$store.state.momo.progress"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-img
                        :src="'/img/icons/' + item.value + '.jpeg'"
                      ></v-img>
                    </v-list-item-avatar>
                    {{ item.text }}
                  </template>

                  <template v-slot:prepend-inner>
                    <v-avatar size="30" class="m-2 mb-5">
                      <v-img :src="selectedIcon"></v-img>
                    </v-avatar>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  placeholder="024xxxxxxx"
                  type="tel"
                  v-if="network"
                  label="Phone Number"
                  v-model="number"
                  outlined
                  :disabled="$store.state.momo.progress"
                ></v-text-field>
              </v-col>

              <v-col class="12">
                <v-alert
                  v-if="false"
                  type="error"
                  color="red lighten-5 red--text"
                  class="font-weight-light"
                >
                  The amount has exceeded the limit, you cannot send more than
                  GHS5,000.
                </v-alert>

                <v-btn
                  depressed
                  block
                  dark
                  color="blue darken-4"
                  rounded
                  :loading="progress"
                  large
                  v-if="validMOMO && !$store.state.momo.requestPendding"
                  @click="pay"
                  >Pay Now!
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2" class="p-0">
            <v-row dense>
              <v-col cols="12" sm="12" class="mx-auto text-center">
                <h4>
                  Please enter OTP code sent to
                  <strong
                    >******{{ number.substring(number.length, 6) }}</strong
                  >
                  to continue
                </h4>
                <span
                  >Did not get code?
                  <v-btn
                    text
                    color="blue"
                    x-small
                    rounded
                    @click="
                      sendOTP();
                      new_request = !new_request;
                    "
                    :loading="progress"
                    >request new code</v-btn
                  >
                  or
                  <v-btn text color="blue" x-small @click="step = 1"
                    >change number</v-btn
                  >
                </span>
                <v-alert
                  v-if="new_request && !progress"
                  type="success"
                  color="green lighten-5 green--text"
                  dismissible
                  class="p-1"
                >
                  <h5>New OTP Sent</h5>
                </v-alert>
              </v-col>
              <v-col cols="12" sm="8" class="mx-auto text-center">
                <v-progress-circular
                  size="40"
                  color="blue darken-4"
                  indeterminate
                  v-if="verifyingOTP"
                ></v-progress-circular>
                <v-otp-input
                  v-else
                  ref="otpInput"
                  input-classes="form-control border"
                  separator="&nbsp;"
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="handleOTPChange"
                  @on-complete="handleOTPComplete"
                />
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>

    <v-card-text v-if="business.country.code === 'KE'">
      <center>
        <h1 class="font-weight-black mb-0 pb-0">
          {{ amount | toMoney | currency_symbol }}
        </h1>
        <small class="text-muted mb-3">Amount Due</small>
      </center>

      <v-form ref="KEcheckoutForm">
        <v-text-field
          placeholder="254xxxxxxxxx"
          type="tel"
          label="Phone Number"
          v-model="numberKE"
          :rules="$store.state.requiredRule"
          outlined
          :disabled="$store.state.momo.progress"
        ></v-text-field>

        <v-btn
          depressed
          block
          dark
          color="blue darken-4"
          rounded
          :loading="progress"
          x-large
          @click="KEMOMOpay"
          >Pay Now!
        </v-btn>
      </v-form>
    </v-card-text>
    <v-card-text v-if="business.country.code === 'RW'">
      <center>
        <h1 class="font-weight-black mb-0 pb-0">
          {{ amount | toMoney | currency_symbol }}
        </h1>
        <small class="text-muted mb-3">Amount Due</small>
      </center>

      <v-form ref="RWcheckoutForm">
        <v-text-field
          placeholder="07########"
          type="tel"
          label="Phone Number"
          v-model="numberKE"
          :rules="$store.state.requiredRule"
          outlined
          :disabled="$store.state.momo.progress"
        ></v-text-field>

        <v-btn
          depressed
          block
          dark
          color="blue darken-4"
          rounded
          :loading="progress"
          x-large
          @click="RWMOMOpay"
          >Pay Now!
        </v-btn>
      </v-form>
    </v-card-text>

    <v-dialog persistent width="500" v-model="$store.state.momo.failed">
      <v-card>
        <v-card-title class="font-weight-light">
          Transaction failed
        </v-card-title>
        <v-card-text>
          <h4 class="font-weight-light">{{ $store.state.momo.message }}</h4>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red"
            block
            depressed
            rounded
            dark
            x-large
            @click="
              $store.state.momo.requestPendding = false;
              $store.state.momo.progress = false;
              $store.state.momo.failed = false;
            "
            >{{ $t("main.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Checkout",
  props: [
    "amount",
    "account_id",
    "record_type",
    "record_id",
    "metaData",
    "isEligibleForDiscount",
    "discountedPrice",
  ],

  data() {
    return {
      userOTP: null,
      verifyingOTP: false,
      new_request: false,
      otp: null,
      step: 1,
      requestPendding: false,
      network: null,
      number: "",
      progress: false,
      ref: "",
      failed: false,
      failed_meesage: "",
      numberKE: "",
      networks: [
        {
          text: "AirtelTigo",
          value: "AirtelTigo",
        },
        {
          text: "MTN",
          value: "MTN",
        },
        {
          text: "Vodafone",
          value: "VODAFONE",
        },
      ],

      numberPattern: [
        {
          network: "MTN",
          code: "024",
        },

        {
          network: "MTN",
          code: "055",
        },
        {
          network: "MTN",
          code: "054",
        },

        {
          network: "MTN",
          code: "059",
        },

        {
          network: "VODAFONE",
          code: "020",
        },

        {
          network: "VODAFONE",
          code: "050",
        },

        {
          network: "AIRTEL",
          code: "027",
        },

        {
          network: "AIRTEL",
          code: "057",
        },

        {
          network: "AIRTEL",
          code: "026",
        },
      ],
    };
  },
  watch: {
    complete() {
      this.step = 1;
      this.verifyingOTP = false;

      this.$emit("paymentComplete", {
        ref: this.ref,
        account_id: this.account_id,
        amount: this.isEligibleForDiscount ? this.discountedPrice : this.amount,
      });
    },
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      let d = new Date();
      let timestamp = d.getTime();
      return "Subscription_" + timestamp + "" + text;
    },
    business() {
      return this.$store.state.user.user_infor.current_business;
    },

    complete() {
      return this.$store.state.momo.complete2;
    },

    validMOMO() {
      return this.network && this.number.length === 10 && !isNaN(this.number);
    },

    /**
     * @return {boolean}
     */
    NumberBelongsToNetwork() {
      let network = this.network;
      let code = this.number.substring(0, 3);

      const foundNetwork = this.numberPattern.find((item) => {
        return item.code === code && item.network === network;
      });

      return Boolean(foundNetwork);
    },

    selectedIcon() {
      return this.network
        ? "/img/icons/" + this.network + ".jpeg"
        : "/img/icons/cellphone-message.png";
    },
  },
  methods: {
    KEMOMOpay() {
      if (this.$refs.KEcheckoutForm.validate()) {
        this.$store.state.momo.progress = true;

        const formData = new FormData();
        formData.append("phone_number", this.numberKE);
        formData.append(
          "amount",
          this.isEligibleForDiscount ? this.discountedPrice : this.amount
        );
        formData.append("account_number", this.numberKE);
        formData.append("account_id", this.account_id);
        formData.append("reference", this.reference);

        if (this.record_id && this.record_type) {
          formData.append("record_type", this.record_type);
          formData.append("record_id", this.record_id);
        }

        if (this.metaData) {
          formData.append("meta_data", this.metaData);
        }

        let oldULR = "/api/ke/init/mobile-checkout";
        oldULR = "/api/collection/mpesa";
        axios
          .post(oldULR, formData)
          .then((res) => {
            const ch = "TANDA-MPESA_" + res.data.id;

            if (res.data.status === "000001") {
              this.ref = res.data.id;

              this.listenForAuth(ch);
              this.$store.state.momo.requestPendding = true;
            } else {
              this.$store.state.momo.progress = false;
              this.$store.state.momo.message = res.data.message;
              this.$store.state.momo.failed = true;
            }
          })
          .catch((error) => {
            this.$store.state.momo.progress = false;
            this.$store.state.momo.message =
              error.response.status === 302
                ? error.response.data
                : "Something went wrong, please try again";
            this.$store.state.momo.failed = true;
          });
      }
    },

    RWMOMOpay() {
      if (this.$refs.RWcheckoutForm.validate()) {
        this.$store.state.momo.progress = true;

        const formData = new FormData();
        formData.append("phone_number", this.numberKE);
        formData.append(
          "amount",
          this.isEligibleForDiscount ? this.discountedPrice : this.amount
        );
        formData.append("account_number", this.numberKE);
        formData.append("account_id", this.account_id);
        formData.append("reference", this.reference);

        if (this.record_id && this.record_type) {
          formData.append("record_type", this.record_type);
          formData.append("record_id", this.record_id);
        }

        if (this.metaData) {
          formData.append("meta_data", this.metaData);
        }

        const URL = "/api/rw/init/mobile-checkout";

        axios
          .post(URL, formData)
          .then((res) => {
            const ch = "PAYPACK_" + res.data.ref;

            if (res.data.status.toUpperCase() === "PENDING") {
              this.ref = res.data.ref;

              this.listenForAuth(ch);

              this.$store.state.momo.requestPendding = true;
            } else {
              this.$store.state.momo.progress = false;
              this.$store.state.momo.message = res.data.message;
              this.$store.state.momo.failed = true;
            }
          })
          .catch((error) => {
            this.$store.state.momo.progress = false;
            this.$store.state.momo.message =
              error.response.status === 302
                ? error.response.data
                : "Something went wrong, please try again";
            this.$store.state.momo.failed = true;
          });
      }
    },
    handleOTPChange() {},

    handleOTPComplete(otp) {
      this.userOTP = otp;
      this.veirifyOTP();
    },

    handleClearOTP() {},

    veirifyOTP() {
      this.verifyingOTP = true;
      const formData = new FormData();
      formData.append("id", this.otp.id);
      formData.append("phone_number", this.otp.recipient_number);
      axios
        .post("/api/otp/verify/" + this.userOTP, formData)
        .then((res) => {
          this.verifyingOTP = false;
          this.otp = res.data;
          if (res.data.status) {
            this.pay();
          } else {
            this.$store.state.momo.message = res.data.message;
            this.$store.state.momo.failed = true;
          }
        })
        .catch((error) => {
          this.verifyingOTP = false;
          this.$store.state.momo.message =
            "Something went wrong, could not verify the OTP code;";
          this.$store.state.momo.failed = true;
        });
    },

    sendOTP() {
      this.progress = true;

      if (this.number) {
        axios
          .get("/api/otp/code/" + this.number)
          .then((res) => {
            this.otp = res.data;

            this.progress = false;

            if (res.data.status) {
              this.step = 2;
            } else {
              this.$store.state.momo.message = res.data.message;
              this.$store.state.momo.failed = true;
            }
          })
          .catch((error) => {
            this.progress = false;

            this.$store.state.momo.message =
              "Something went wrong, could not send OTP";
            this.$store.state.momo.failed = true;
          });
      }
    },

    pay() {
      this.$store.state.momo.progress = true;

      let formData = new FormData();

      formData.append("network", this.network);
      formData.append("account_number", this.number);
      formData.append(
        "amount",
        this.isEligibleForDiscount ? this.discountedPrice : this.amount
      );
      formData.append("account_id", this.account_id);

      if (this.record_id && this.record_type) {
        formData.append("record_type", this.record_type);
        formData.append("record_id", this.record_id);
      }

      //formData.append('otp_id',this.otp.id);

      axios
        .post("/api/nsano/debit", formData)
        .then((res) => {
          this.ref = res.data.reference;
          this.$store.state.momo.requestPendding = true;
          this.listenForAuth();
        })
        .catch((error) => {
          this.$store.state.momo.progress = false;
          this.$store.state.momo.message =
            error.response.status === 302
              ? error.response.data
              : "Something went wrong, please try again";
          this.$store.state.momo.failed = true;
        });
    },

    listenForAuth(ch = null) {
      const channel = ch ? ch : `debitconfirmation_${this.ref}`;

      this.sockets.subscribe(channel, (e) => {
        let message = e;
        if (message.data.code !== "00") {
          this.$store.state.momo.message =
            message.data.system_msg.length > 0
              ? message.data.system_msg
              : message.data.error_message;
          this.$store.state.momo.failed = true;
        } else {
          this.$store.state.momo.progress = false;
          this.$store.state.momo.complete2 = !this.$store.state.momo.complete2;
          this.$store.state.momo.requestPendding = false;
        }
      });
    },
  },
};
</script>

<style scoped></style>
