<template>
  <v-row>
    <v-col cols="12" sm="4" class="mx-auto">
      <v-stepper
        class="pa-0"
        v-model="step"
        style="box-shadow: none !important"
      >
        <v-stepper-items class="pa-0" style="box-shadow: none !important">
          <v-stepper-content class="pa-0" step="1">
            <v-card flat>
              <v-card-text>
                <div>
                  <h1 class="font-weight-light red--text">
                    Subscription Ended
                  </h1>

                  <h3 class="text-h6 font-weight-light">
                    Your subscription for
                    <strong class="fw-bold">{{
                      $store.state.user.user_infor.current_business.name
                    }}</strong>
                    has ended.
                  </h3>
                </div>

                <v-alert
                  v-if="
                    $store.state.user.user_infor.current_business.subscription
                      .name !== 'none'
                  "
                  color="warning lighten-5 warning-text"
                >
                  <div>
                    <h3>
                      Only businesses active on <strong>Starter</strong>,
                      <strong>Enterprise</strong>, <strong>Venture</strong>,
                      <strong>Pro</strong> and <strong>Plus</strong>
                      are allowed to have multiple user access.
                    </h3>
                    <strong
                      >Contact the owner of this business to upgrade their
                      plan</strong
                    >
                  </div>
                </v-alert>
                <!--CHECK: CANNOT PUSH TO '/plans' AS THIS COMPONENT IS BLOCKING ALL OTHER PAGES-->
                <v-btn
                  color="green"
                  dark
                  block
                  x-large
                  rounded
                  depressed
                  @click="showPlans = true"
                  >Subscribe to a plan to continue
                </v-btn>

                <v-btn
                  v-if="
                    mybusinesses ||
                    $store.state.user.user_infor.invited_businesses
                  "
                  class="font-weight-light my-4 mx-auto text-center white--text"
                  color="blue"
                  block
                  rounded
                  x-large
                  @click="showBusinesses = true"
                  >Switch to a different business
                </v-btn>

                <v-dialog v-model="showBusinesses" scrollable max-width="500">
                  <v-card
                    elevation="1"
                    rounded="lg"
                    style="overflow: auto"
                    class="pa-5 mx-auto"
                  >
                    <v-card-title
                      class="fw-bold"
                      v-if="invitedBusinesses && invitedBusinesses.length"
                      >Businesses You were invited to
                    </v-card-title>

                    <business-item
                      v-for="business in invitedBusinesses"
                      :key="business.id"
                      :business="business"
                      v-if="
                        business.id !==
                        $store.state.user.user_infor.current_business.id
                      "
                    ></business-item>
                    <v-skeleton-loader
                      type="list-item@10"
                      v-if="progress"
                    ></v-skeleton-loader>
                    <div v-if="mybusinesses">
                      <v-card-title class="fw-bold mt-3 mb-1"
                        >Your Businesses
                      </v-card-title>
                      <business-item
                        v-for="business in mybusinesses"
                        :key="business.id"
                        :business="business"
                      ></business-item>
                    </div>
                  </v-card>
                </v-dialog>
                <v-btn
                  v-if="
                    $store.state.user.user_infor.current_business.subscription
                      .name !== 'none'
                  "
                  @click="step = 2"
                  large
                  block
                  rounded
                  depressed
                  color="blue lighten-5 blue--text"
                  >Create a new business
                </v-btn>
                <v-btn
                  large
                  block
                  rounded
                  depressed
                  color="red lighten-5 red--text mt-2"
                  @click="logoutDialog = true"
                >
                  Logout
                </v-btn>
              </v-card-text>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <new-business-component @back="step = 1"></new-business-component>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>

    <v-container fluid>
      <plans-component v-if="showPlans"></plans-component>
    </v-container>

    <v-dialog width="400" v-model="logoutDialog">
      <v-card>
        <v-card-title class="font-weight-light"
          >{{ $t("main.confirm") }}
        </v-card-title>
        <v-card-text>
          <h3>{{ $t("main.logout_message") }}</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="logout"
            :loading="logoutLoading"
            block
            large
            color="red"
            dark
            rounded
            depressed
          >
            {{ $t("main.logout") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import BusinessItem from "./BusinessItem";
import NewBusinessComponent from "./NewBusinessComponent";
import PlansComponent from "../plansComponent";
import axios from "axios";

export default {
  name: "BusinessExpiredGuard",
  components: { PlansComponent, NewBusinessComponent, BusinessItem },
  data() {
    return {
      mybusinesses: [],
      progress: false,
      logoutDialog: false,
      loging_out: false,
      step: 1,
      invitedBusinesses: [],
      logoutLoading: false,
      showBusinesses: false,
      showPlans: false,
    };
  },
  methods: {
    logout() {
      this.logoutLoading = true;
      axios
        .get("/api/auth/logout")
        .then((res) => {
          this.logoutLoading = false;
          window.reload();
        })
        .catch((error) => {});
    },
    getInvitedBusinesses() {
      this.progress = true;

      axios.get("/api/business/invited").then((res) => {
        this.invitedBusinesses = res.data;
        this.progress = false;
      });
    },

    GetMyBusinesses() {
      this.progress = false;
      axios.get("/api/getbusinesses").then((res) => {
        this.mybusinesses = res.data;
        this.progress = false;
      });
    },
  },
  mounted() {
    this.GetMyBusinesses();
    this.getInvitedBusinesses();
  },
};
</script>

<style scoped></style>
